import React from "react";
import "./legal.scss";
import "swiper/css";
import { Accordion } from "react-bootstrap";

//components
import Banner from "../../components/Banner/Banner";
import { aboutUs } from "../../helpers/constant-words";

//images
import { faqopen, aboutusbanner } from "../../images";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

function Legal() {
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={aboutusbanner}
          caption="Ruby, Bandra (W)"
          title="Thriving in the heart of Mumbai"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" direction="up" triggerOnce>
        <div className="my_container">
          <div className="faqs_content">
            <div className="title">
              <h1>LEGAL</h1>
            </div>
            <div className="faqs">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      What documents must the buyer go through before purchasing
                      the apartment from the builder?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    The buyer must go through the{" "}
                    <a
                      href="https://maharerait.mahaonline.gov.in/searchlist/search?MenuID=1069"
                      target="_blank"
                      rel="noreferrer"
                    >
                      RERA page
                    </a>{" "}
                    of the project and make sure that the floor/flat being
                    purchased is approved by the municipal authority. All
                    details regarding the agreement for sale, title certificate,
                    commencement certificate, amenities in the building etc
                    shall be mentioned on the RERA page
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Who shall pay the stamp duty?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    The buyer is liable to pay the stamp duty unless agreed upon
                    differently by the concerned parties.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      Difference between a Sale Deed and an agreement for sale?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    An agreement for sale is a promise in future, that the
                    property will be transferred to the rightful owner while a
                    sale deed is the actual transfer of property ownership to
                    the buyer.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is a Property card?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    A Malmatta Patrak, also known as a property card, is{" "}
                    <strong>
                      a document that identifies the owner of real estate in
                      metropolitan areas and is issued by the local government
                      in Maharashtra.
                    </strong>{" "}
                    The property card contains information such as the name of
                    the proprietor, the acreage of the land, the survey number,
                    the plot number, the district, the taluka, the village, the
                    liabilities, and the litigations, among other things. In
                    addition to validating the land’s historical ownership data,
                    it may also help to prevent unlawful encroachment on the
                    property.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      What does the Ready Reckoner value of a property mean? Is
                      stamp duty payable on the Ready Reckoner value of the
                      property or the value stated in the agreement?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    The ready reckoner rate in Mumbai is{" "}
                    <strong>
                      the government's estimate of minimum property values in
                      various locations.
                    </strong>{" "}
                    However, the stamp is payable on the agreement value of the
                    property or the market value, depending on whichever is
                    higher.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
}

export default Legal;
