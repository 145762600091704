import { useEffect, useState } from "react";
import "./contact.scss";
import "swiper/css";

//components
import Banner from "../../components/Banner/Banner";
import { aboutUs } from "../../helpers/constant-words";

//images
import {
  location,
  call,
  mail,
  dropdownarrow,
  uploadicon,
  orangecta,
  contactusbanner,
} from "../../images";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import Joi from "joi";
import _ from "lodash";
import http from "../../helpers/http";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

const Contact = () => {
  const types = ["Buyer/Investor", "Vendor", "Silent Partner", "Others"];
  const locations = ["location 1", "location 2"];

  const [values, setValues] = useState({
    name: "",

    email: "",
    phone: "",
    type: "",
    location: "",
    message: "",

    document: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");

  let base_url = config.api_url + "/forms/contact";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Contact Number"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.document) {
      if (values.document.size > sizeLimit * 1024 * 1024) {
        errors["document"] = `File needs to be under ${sizeLimit} MB`;
      }
      let allowedExt = [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-office",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/pdf",
        "application/msword",
        "application/vnd.ms-office",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedExt.includes(values.document.type)) {
        errors["document"] = "Please upload valid file type";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    let formData = new FormData();
    for (const [key, data] of Object.entries(
      _.omit(values, ["document", "phone"])
    )) {
      formData.append(key, data || "");
    }

    if (values.phone) {
      formData.append("phone", `${countryCode}${values.phone}`);
    }
    if (values.document) {
      formData.append("document", values.document);
    }

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, formData);

    if (data) {
      setValues({
        name: "",
        email: "",
        phone: "",
        type: "",
        location: "",
        message: "",
        document: "",
      });
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const fetchCodes = async () => {
    const data = await http.get(`${config.api_url}/general/countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data.data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  return (
    <>
      <div className="main_container">
        <Fade duration="2000" triggerOnce>
          <Banner
            img={contactusbanner}
            caption="Ruby, Bandra(W)"
            title="Thriving in the heart of Mumbai"
            linkhead="Speak To Us Today"
            linkdesc="A call away from your dream home"
            linkurl={aboutUs}
          />
        </Fade>

        <Fade duration="2000" direction="up" triggerOnce>
          <section className="contact_container">
            <div className="contact">
              <div className="contact_form">
                <div className="text">
                  <div className="heading">
                    <h1>GET IN TOUCH</h1>
                  </div>
                  <div className="info">
                    <a href="https://goo.gl/maps/Cv88W3Jn5idoHqM48" target="_blank" rel="noreferrer">
                    <p>
                      PH House, Gr. & 1st Floor, 14th Road, Khar (W), Mumbai 400
                      052.
                    </p>
                    </a>
                  </div>
                </div>
                <div className="form">
                  <form onSubmit={handleSubmit}>
                    <div className="form_flex">
                      <div className="form_field_wrapper form_field_customwidth">
                        <input
                          type="text"
                          className="form_input"
                          placeholder="Name*"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.name}</p>
                      </div>
                      <div className="form_field_wrapper form_field_customwidth">
                        <input
                          type="text"
                          className="form_input"
                          placeholder="Email ID*"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.email}</p>
                      </div>
                      {/* Mobile no box start here */}
                      <div className="mobile_number_box form_field_wrapper">
                        <div className="country_code">
                          <select
                            className=" countrycode_select"
                            name="country_code"
                            value={countryCode}
                            onChange={(e) => setcountryCode(e.target.value)}
                          >
                            {codes.map((code, i) => {
                              return (
                                <option value={`+${code}`} key={i}>
                                  {`+${code}`}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            className="dropwdown_icon"
                            width={16}
                            height={8}
                            loading="lazy"
                            alt="dropdown icon"
                            src={dropdownarrow}
                          />
                          <p className="error_message"></p>
                        </div>
                        <div className="mobile_number">
                          <input
                            type="tel"
                            className="form_input"
                            placeholder="Mobile Number*"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                          <p className="error_message">{formError.phone}</p>
                        </div>
                      </div>
                      {/* Mobile no box end here */}
                      <div className="country_location_box form_field_customwidth">
                        <div className="country_code">
                          <select
                            className="countrycode_select"
                            name="type"
                            value={values.type}
                            onChange={handleChange}
                          >
                            <option disabled value="">
                              Select Type
                            </option>
                            {types.map((type, i) => {
                              return (
                                <option value={type} key={i}>
                                  {type}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            className="dropwdown_icon"
                            width={16}
                            height={8}
                            loading="lazy"
                            alt="dropdown icon"
                            src={dropdownarrow}
                          />
                          <p className="error_message"></p>
                        </div>
                        <p className="error_message">{formError.type}</p>
                      </div>
                      <div className="country_location_box form_field_customwidth">
                        
                      <div className="form_field_wrapper form_field_customwidth">
                        <input
                          type="text"
                          className="form_input"
                          placeholder="Location"
                          name="location"
                          value={values.location}
                          onChange={handleChange}
                        />
                         <p className="error_message"></p>
                      </div>
                        {/* <div className="country_code">
                          <select
                            className=" countrycode_select"
                            name="location"
                            value={values.location}
                            onChange={handleChange}
                          >
                            <option disabled value="">
                              `Select Location`
                            </option>
                            {locations.map((location, i) => {
                              return (
                                <option value={location} key={i}>
                                  {location}
                                </option>
                              );
                            })}
                          </select>
                          <img
                            className="dropwdown_icon"
                            width={16}
                            height={8}
                            loading="lazy"
                            alt="dropdown icon"
                            src={dropdownarrow}
                          />
                          <p className="error_message"></p>
                        </div> */}
                        <p className="error_message">{formError.location}</p>
                      </div>
                      <div className="form_field_wrapper form_field_customwidth">
                        <input
                          type="text"
                          className="form_input textarea"
                          placeholder="Message"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <p className="error_message">{formError.message}</p>
                      </div>
                      <div className="form_field_wrapper form_field_customwidth">
                        <p className="upload_heading">Upload resume</p>
                        <input
                          type="file"
                          id="uploadFile"
                          hidden
                          name="document"
                          onChange={handleChange}
                        />
                        <label htmlFor="uploadFile" className="upload_label">
                          <img
                            src={uploadicon}
                            alt=""
                            className="upload_icon"
                          />
                          <span className="section_desc">Choose File</span>
                        </label>
                        <div className="format_size_flex">
                          <p className="upload_heading">File Format : PDF, DOC, PPT</p>
                          <p className="upload_heading">
                            File Size: Less than {sizeLimit} MB
                          </p>
                        </div>
                        <p className="error_message">{formError.document}</p>
                        <p className="">
                          {" "}
                          {values?.document?.name || "No file selected"}
                        </p>
                      </div>

                      <div className="form_field_wrapper form_field_customwidth form_submit">
                        <div className="cta_wrapper">
                          <button className="project-button">
                            {loading ? "Loading.." : "Submit"}
                            <img
                              src={orangecta}
                              alt="Button arrow design"
                              className="cta_right_arrow"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="map_content">
                <div className="map">
                  <iframe
                    title="google map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.773553315315!2d72.833378!3d19.073691300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9dfe167d2d5%3A0x70b5877fab68b634!2sP.H%20House%20L.Nagpal%20Developers!5e0!3m2!1sen!2sin!4v1669807532396!5m2!1sen!2sin"
                    className="google_map"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </Fade>

        <Fade duration="2000" direction="up" triggerOnce>
          <section className="support_container">
            <div className="support_content">
              <div className="support">
                <div className="heading">
                  <h1>QUICK SUPPORT</h1>
                  <p>You can get all the contact information here.</p>
                </div>
                <div className="support_info">
                  <div className="contact_info location">
                    <div className="icon">
                      <img src={location} alt="location"></img>
                    </div>
                    <div className="text">
                      <div className="heading">
                        <p>VISIT US</p>
                      </div>
                      <div className="info">
                        <p>14th Rd, Khar West, Mumbai, Maharashtra 400052</p>
                      </div>
                    </div>
                  </div>
                  <div className="contact_info call">
                    <div className="icon">
                      <img src={call} alt="call"></img>
                    </div>
                    <div className="text">
                      <div className="heading">
                        <p>CALL US</p>
                      </div>
                      <div className="info">
                      <p>
                          <a href="tel:+919819925114">+91 9819925114</a>
                        </p>
                        <p>
                          <a href="tel:022 26467300">022 26467300</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="contact_info mail">
                    <div className="icon">
                      <img src={mail} alt="mail"></img>
                    </div>
                    <div className="text">
                      <div className="heading">
                        <p>EMAIL US </p>
                      </div>
                      <div className="info">
                        <p>
                          <a href="mailto: lnagpalbuilders@gmail.com">
                            lnagpalbuilders@gmail.com
                          </a>
                        </p>
                        {/* <p>
                        <a href="mailto:help@nagpaldevelopers.com">
                          help@nagpaldevelopers.com
                        </a>
                      </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fade>
      </div>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
};

export default Contact;
