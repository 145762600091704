/*
Component build by Talha
Date: 24th August 2022
*/

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectCreative, Pagination } from "swiper";
import "swiper/css/pagination";
import {
  images,
  quote,
  leftarroworange,
  milestoneextraline,
  milestoneline,
  rightarroworange,
  leftarrow,
  rightarrow,
  feature1,
  feature2,
  feature3,
  locationicon,
  blackprev,
  blacknext,
  orangecta,
} from "../../images";
import { Link } from "react-router-dom";
import "../../components/Banner/banner.scss";
import "animate.css";
import "./homepage.scss";
import Counteritem from "../../components/CounterItem/CounterItem";
import { banners, milestoneData, testimonials } from "../../Data/HomepageData";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CommonCta from "../../components/CommonCta/CommonCta";
import {
  aboutUs,
  projectsRoute,
  contactUs,
} from "../../helpers/constant-words";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { findWhere as getFeaturedProjects } from "../../Data/projects";
import EnquireForm from "../../components/EnquireForm/EnquireForm";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const Homepage = () => {
  const { width } = useWindowDimensions();

  const obj = { a: 1 };

  const outerFunc = () => {
    return () => {
      return (obj.a = obj.a + 1);
    };
  };

  const res = outerFunc();

  res();
  obj.a = 2;

  console.log(res());

  const featuredProjects = getFeaturedProjects({ isFeatured: 1 });

  const milestoneList = milestoneData.map((item, i) => (
    <>
      <SwiperSlide key={i}>
        <div className="milestone-box">
          <Link to={item.link}>
            <h4 className="milestone_heading">{item.title}</h4>
            <p className="milestone_location">
              <img
                loading="lazy"
                className="location_icon"
                width={11.84}
                height={15.78}
                src={locationicon}
                alt="location icon"
              />
              {item.location}
            </p>
          </Link>
          <img
            loading="lazy"
            className="milestone-line"
            src={milestoneline}
            alt="Milestone line svg"
          />
          <h3 className="milestone_year">{item.year}</h3>
        </div>
      </SwiperSlide>
    </>
  ));

  const onGoingProjList = featuredProjects?.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="row ongoing_box">
        <div className="col-lg-6">
          {width > 600 ? (
            <div className="arrow_container">
              <div className="arrows_wrapper arrow_desktop">
                <img
                  loading="lazy"
                  src={orangecta}
                  alt="left arrow logo"
                  className="ongoing-prev home-prev"
                />
                <img
                  loading="lazy"
                  src={orangecta}
                  alt="right arrow logo"
                  className="ongoing-next"
                />
              </div>
            </div>
          ) : null}

          <div className="ongoing_proj_text">
            <h5 className="ongoing_heading">{item.status} Projects</h5>
            <p
              className="ongoing_desc"
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></p>

            <div className="count">
              {i + 1 < 10 ? "0" + (i + 1) : i + 1}&nbsp;/&nbsp;
              {featuredProjects?.length < 10
                ? "0" + featuredProjects?.length
                : featuredProjects?.length}
            </div>

            <h6 className="ongoing_name">{item.title}</h6>

            {item?.highlights?.map((item, i) => {
              return (
                <p className="ongoing_location" key={i}>
                  <div className="location_wrapper">
                    <img
                      loading="lazy"
                      className="location_icon"
                      src={item.icon}
                      alt="location"
                    />
                  </div>
                  {item.title}
                </p>
              );
            })}

            {/* <p className="ongoing_location">
                <div className="location_wrapper">
                  <img
                    loading="lazy"
                    className="location_icon"
                    src={locationiconhome}
                  />
                </div>
                Location : {item.location}
              </p>
              <p className="ongoing_area">
                <div className="area_wrapper">
                  <img loading="lazy" className="area_icon" src={areaicon} />
                </div>
                Area : {item.area}
              </p>
              <p className="ongoing_space">
                <div className="space_wrapper">
                  <img loading="lazy" className="space_icon" src={spaceicon} />
                </div>
                {item.space}
              </p> */}
          
          {width > 600 ? (
            <div className="cta_wrapper cta_wrapper_project_desk">
              <CommonCta linkTo={`/projects/ongoing`} ctaText="View All Projects" />
            </div>
          ) : null}
        </div>
        </div>
        <div className="col-lg-6">
          <div className="image_container">
            <img
              loading="lazy"
              className="ongoing_project"
              width={1078}
              height={825}
              src={item.featured_image.image}
              alt={item.featured_image.alt}
            />
          </div>
        </div>
      </div>
    </SwiperSlide>
  ));

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span className="' +
        className +
        '">' +
        "0" +
        banners[index].id +
        "</span>"
      );
    },
  };

  return (
    <>
     <MetaDecorator
        metaTitle="L Nagpal Builders: One of the Mumbai's Top Developer"
        metaDesc="Crafting exquisite Mumbai homes with quality, transparency & timely delivery since 2001."
        canonicalLink={window.location.href}
      />
      <Fade duration="1000" triggerOnce>
        <section className="bannersec">
          <Swiper
            pagination={pagination}
            modules={[Navigation, Autoplay, Pagination, EffectCreative]}
            allowTouchMove={false}
            loop={true}
            navigation={{
              prevEl: ".bannersec .arrowleft",
              nextEl: ".bannersec .arrowright",
            }}
            grabCursor={false}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            // effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
          >
            {banners.map((item, i) => (
              <>
                <SwiperSlide key={item.id}>
                  <main className="common-banner">
                    <div className="banner-background-wrapper">
                      <img
                        className="banner-img"
                        src={item.backimg}
                        alt={item.alt}
                      />
                      {/* <div className="banner-link-wrapper">
                        <div className="banner-text-container">
                          <h3 className="banner-link-heading section_subheading">
                            {item.ctahead}
                          </h3>
                          <p className="banner-link-desc section_desc">
                            {item.ctadesc}
                          </p>
                        </div>
                        <Link to={item.cta}>
                          <img loading="lazy"
                            className="arrow-img"
                            src={rightarroworange}
                            alt="banner-img"
                          />
                        </Link>
                      </div> */}
                    </div>
                    <div className="my_container">
                      <div className="banner-text-wrapper">
                        <p className="banner-caption animate__animated animate__fadeInUp">
                          {item.head}
                        </p>
                        <h2 className="banner-title animate__animated animate__fadeInUp">
                          {item.desc}
                        </h2>
                      </div>
                    </div>
                  </main>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
          {width > 600 ? (
            <Link to={contactUs}>
              <div className="banner-link-wrapper">
                <div className="banner-text-container">
                  <h3 className="banner-link-heading section_subheading">
                    Speak To Us Today
                  </h3>
                  <p className="banner-link-desc section_desc">
                    A call away from your dream home
                  </p>
                </div>

                <img
                  loading="lazy"
                  className="arrow-img"
                  src={rightarroworange}
                  alt="arrow"
                />
              </div>
            </Link>
          ) : null}
        </section>
      </Fade>

      <Fade duration="2000" direction="up" triggerOnce>
        <section className="home_sec1">
          <div className="milestone-wrapper">
            <div className="arrows_wrapper">
              <img
                loading="lazy"
                src={leftarroworange}
                alt="left arrow logo"
                className=" milestone-prev"
              />
            </div>
            <img
              loading="lazy"
              className="milestone-extra-line"
              src={milestoneextraline}
              alt="Milestone line svg"
            />
            <div className="my_container">
              <Swiper
                modules={[Navigation]}
                spaceBetween={0}
                navigation={{
                  prevEl: ".milestone-prev",
                  nextEl: ".milestone-next",
                }}
                autoHeight={true}
                // loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                }}
              >
                {milestoneList}
              </Swiper>
            </div>
            <img
              loading="lazy"
              className="milestone-extra-line"
              src={milestoneextraline}
              alt="Milestone line svg"
            />

            <div className="arrows_wrapper">
              <img
                loading="lazy"
                src={rightarroworange}
                alt="right arrow logo"
                className=" milestone-next"
              />
            </div>
          </div>
        </section>
      </Fade>

      <Fade duration="2000" direction="up" triggerOnce>
        <section className="home_sec2">
          <div className="my_container">
            <div className="row about-flex-box">
              <div className="col-lg-6">
                <div className="counter_wrapper_flex">
                  <h4 className="about_heading">HISTORY & MILESTONES</h4>
                  <p className="about_desc">
                    Having a strong key-hold in Mumbai’s Luxury suburban region,
                    we’ve grown to revolutionize the real estate industry.
                    Ethics, Experience, Entrepreneurship & Expertise are the 4
                    E’s with which we’ve made our company grow by leaps and
                    bounds.
                  </p>
                  <div className="counter_cont">
                    <Link to={`/projects/completed`} state={1}>
                      <Counteritem
                        startCount={5}
                        endCount={15}
                        duration={3}
                        countTitle="BUILDINGS DEVELOPED"
                        plus
                        // aboveThousand
                      />
                    </Link>
                  </div>
                  <div className="counter_cont">
                    <Counteritem
                      startCount={599000}
                      endCount={600000}
                      duration={3}
                      countTitle="SQ. FT. CONSTRUCTED"
                      plus
                    />
                  </div>
                  <div className="counter_cont">
                    <Counteritem
                      startCount={200}
                      endCount={250}
                      duration={3}
                      countTitle="HOMES DELIVERED"
                      plus
                    />
                  </div>
                  <div className="counter_cont">
                    <Link to={`/projects/ongoing`} state={0}>
                      <Counteritem
                        startCount={0}
                        endCount={5}
                        duration={3}
                        countTitle="ONGOING PROJECTS"
                      />
                    </Link>
                  </div>
                </div>
                {width > 600 ? (
                  <div className="cta_wrapper cta_wrapper_desk">
                    <CommonCta
                      normalCta
                      anchorLink={aboutUs}
                      ctaText="Know More"
                    />
                  </div>
                ) : null}
              </div>
              <div className="col-lg-5">
                <div className="images_container">
                  <div className="building1_wrapper">
                    <img
                      loading="lazy"
                      width={726}
                      height={1016}
                      className="building1_img"
                      src={images.building1.image}
                      alt={images.building1.alt}
                    />
                  </div>
                  <div className="building2_wrapper">
                    <img
                      loading="lazy"
                      width={639}
                      height={423}
                      className="building2_img"
                      src={images.building2.image}
                      alt={images.building2.alt}
                    />
                  </div>
                  <div className="site_icon_wrapper">
                    <img
                      loading="lazy"
                      width={198}
                      height={198}
                      className="site_icon"
                      src={images.siteicon.image}
                      alt={images.siteicon.alt}
                    />
                  </div>
                </div>
              </div>
              {width < 600 ? (
                <div className="cta_wrapper cta_wrapper_mob">
                  <CommonCta
                    normalCta
                    anchorLink={aboutUs}
                    ctaText="Know More"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </Fade>

      <Fade duration="2000" direction="up" triggerOnce>
        <section className="home_sec3">
          <div className="my_container">
            <h4 className="feature_heading">KEY FEATURES</h4>
            <div className="row features_wrapper">
              {/* <div className="col-lg-4">
                <div className="feature_box">
                  <img
                    loading="lazy"
                    className="feature-img"
                    width={96}
                    height={96}
                    src={feature1}
                    alt=""
                  />
                  <h5 className="feature-title">QUALITY & WORKMANSHIP</h5>
                  <p className="feature-desc">
                    We believe and practice the saying–nothing but the best. Our
                    quality of materials used to make a building are of the most
                    superior grade. Our impeccable workmanship skills is what
                    makes design to reality.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="feature_box">
                  <img
                    loading="lazy"
                    className="feature-img"
                    width={96}
                    height={96}
                    src={feature2}
                    alt=""
                  />
                  <h5 className="feature-title">STIPULATED DELIVERY PERIOD</h5>
                  <p className="feature-desc">
                    We commit what we can offer. We’ve delivered all of our buildings within the Stipulated time period along with the Occupation certificate.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="feature_box">
                  <img
                    loading="lazy"
                    className="feature-img"
                    width={96}
                    height={96}
                    src={feature3}
                    alt=""
                  />
                  <h5 className="feature-title">TRANSPARENCY</h5>
                  <p className="feature-desc">
                  As Carry Somers said, "There is no beauty without truth and there is no truth without transparency.". Our Ethical values have always been to be completely transparent and speak the truth 
                  with all of our customers/clients.
                  </p>
                </div>
              </div> */}

              <Swiper
                modules={[Navigation]}
                spaceBetween={0}
                navigation={{
                  prevEl: ".features-prev",
                  nextEl: ".features-next",
                }}
                autoHeight={true}
                loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="feature_box">
                    <img
                      loading="lazy"
                      className="feature-img"
                      width={96}
                      height={96}
                      src={feature1}
                      alt=""
                    />
                    <h5 className="feature-title">QUALITY & WORKMANSHIP</h5>
                    <p className="feature-desc">
                      We believe and practice the saying–nothing but the best.
                      Our quality materials used to make a building are of the
                      most superior grade. Our impeccable workmanship skills are
                      what make design to reality
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="feature_box">
                    <img
                      loading="lazy"
                      className="feature-img"
                      width={96}
                      height={96}
                      src={feature2}
                      alt=""
                    />
                    <h5 className="feature-title">
                      STIPULATED DELIVERY PERIOD
                    </h5>
                    <p className="feature-desc">
                      We commit to what we can offer. We’ve delivered all of our
                      buildings within the stipulated period along with the
                      Occupation Certificate (OC).
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="feature_box">
                    <img
                      loading="lazy"
                      className="feature-img"
                      width={96}
                      height={96}
                      src={feature3}
                      alt=""
                    />
                    <h5 className="feature-title">TRANSPARENCY</h5>
                    <p className="feature-desc">
                      As Carry Somers said, "There is no beauty without truth,
                      and there is no truth without transparency". Our ethical
                      values have always been to be completely transparent and
                      speak the truth with all our customers/clients.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
              {width < 834 ? (
                <div className="arrows_wrapper">
                  <img
                    loading="lazy"
                    src={blackprev}
                    alt="left arrow logo"
                    className="leftarrowimg features-prev"
                  />
                  <img
                    loading="lazy"
                    src={blacknext}
                    alt="right arrow logo"
                    className="rightarrowimg features-next"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </Fade>

      <section className="home_sec4">
        <Fade duration="2000" triggerOnce>
          <div className="ongoing_project_container">
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              spaceBetween={10}
              navigation={{
                prevEl: ".ongoing-prev",
                nextEl: ".ongoing-next",
              }}
              autoHeight={true}
            >
              {onGoingProjList}
            </Swiper>
            {width < 600 ? (
              <div className="arrow_container">
                <div className="arrows_wrapper arrow_desktop">
                  <img
                    loading="lazy"
                    src={blackprev}
                    alt="left arrow logo"
                    className="ongoing-prev"
                  />
                  <img
                    loading="lazy"
                    src={blacknext}
                    alt="right arrow logo"
                    className="ongoing-next"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Fade>
        {/* {width > 600 ? (
          <div className="cta_wrapper cta_wrapper_project_desk">
            <CommonCta linkTo={projectsRoute} ctaText="View All Projects" />
          </div>
        ) : null} */}
        {width < 600 ? (
          <div className="cta_wrapper cta_wrapper_project_mob">
            <CommonCta linkTo={projectsRoute} ctaText="View All Projects" />
          </div>
        ) : null}
      </section>

      <Fade duration="2000" direction="up" triggerOnce>
        <section className="testimony_content_homepage">
          <div className="testimony_container">
            <div className="testimony_info">
              <div className="testimony_title">
                <h1>WHAT OUR CLIENTS SAY</h1>
              </div>
              <img src={quote} alt="quote"></img>

              <div className="testimony_map">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1}
                  spaceBetween={20}
                  navigation={{
                    prevEl: " .leftarrowimg",
                    nextEl: " .rightarrowimg",
                  }}
                  autoHeight={true}
                  loop={true}
                  // autoplay={{
                  //   delay: 4000,
                  //   disableOnInteraction: false,
                  // }}
                >
                  {testimonials.map((data, i) => (
                    <SwiperSlide key={i}>
                      <div className="testimony_data">
                        <div className="text">
                          <div className="testimony_description">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            ></p>
                          </div>
                        </div>

                        <div className="seperator">
                          <div className="horizontal_line"></div>
                        </div>

                        <div className="client_name">
                          <h6>{data.clientname}</h6>
                          <p>{data.role}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              {/* {width > 600 ? (
                <div className="arrow_container">
                  <div className="arrows_wrapper arrow_desktop">
                    <img
                      src={leftarrow}
                      alt="left arrow logo"
                      className="leftarrowimg"
                    />
                    <img
                      src={rightarrow}
                      alt="right arrow logo"
                      className="rightarrowimg"
                    />
                  </div>
                </div>
              ) : null} */}

              {width < 600 ? (
                <div className="arrows_wrapper arrow_mobile">
                  <img
                    src={leftarrow}
                    alt="left arrow logo"
                    className="leftarrowimg"
                  />
                  <img
                    src={rightarrow}
                    alt="right arrow logo"
                    className="rightarrowimg"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
};

export default Homepage;
