import React from "react";
import { Fade } from "react-awesome-reveal";
import Banner from "../../components/Banner/Banner";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { aboutUs } from "../../helpers/constant-words";
import { commonban } from "../../images";
import "./pagenotfound.scss";

const PageNotFound = () => {
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={commonban}
          caption="Ruby, Bandra(W)"
          title="An artistic dream turning real"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" triggerOnce>
        <div className="my_container">
          <h1 className="notfound">Page Not Found</h1>
        </div>
      </Fade>

      <ScrollToTop />
    </>
  );
};

export default PageNotFound;
