import React, { useState } from "react";
import { Link } from "react-router-dom";
import { orangecta, whitearrowcta } from "../../images";
import "./commoncta.scss";

const CommonCta = ({
  normalCta,
  anchorCta,
  anchorCtawhite,
  anchorLink,
  linkTo,
  ctaText,
  ctaWhite,
  target = "_blank",
}) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  if (normalCta) {
    return (
      <a
        className={`${
          ctaWhite
            ? "right_arrow_cta_flex cta_white"
            : "right_arrow_cta_flex apply_hover"
        }`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        href={anchorLink}
      >
        <span className="cta_text">{ctaText}</span>
        {ctaWhite ? (
          <img
            src={orangecta}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        ) : (
          <img
            src={mouseEnter ? orangecta : orangecta}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        )}
      </a>
    );
  }

  if (anchorCta) {
    return (
      <a
        className={`${
          ctaWhite
            ? "right_arrow_cta_flex cta_white"
            : "right_arrow_cta_flex apply_hover"
        }`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        href={anchorLink}
        target={target}
      >
        <span className="cta_text">{ctaText}</span>
        {ctaWhite ? (
          <img
            src={orangecta}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        ) : (
          <img
            src={mouseEnter ? orangecta : orangecta}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        )}
      </a>
    );
  }

  if (anchorCtawhite) {
    return (
      <a
        className={`${
          ctaWhite
            ? "right_arrow_cta_flex cta_white"
            : "right_arrow_cta_flex apply_hover"
        }`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        href={anchorLink}
        target={target}
      >
        <span className="cta_text">{ctaText}</span>
        {ctaWhite ? (
          <img
            src={whitearrowcta}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        ) : (
          <img
            src={mouseEnter ? whitearrowcta : whitearrowcta}
            alt="Button arrow design"
            className="cta_right_arrow"
          />
        )}
      </a>
    );
  }

  return (
    <Link
      className={`${
        ctaWhite
          ? "right_arrow_cta_flex cta_white"
          : "right_arrow_cta_flex apply_hover"
      }`}
      to={linkTo}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
    >
      <span className="cta_text">{ctaText}</span>
      {ctaWhite ? (
        <img
          src={orangecta}
          alt="Button arrow design"
          className="cta_right_arrow"
        />
      ) : (
        <img
          src={mouseEnter ? orangecta : orangecta}
          alt="Button arrow design"
          className="cta_right_arrow"
        />
      )}
    </Link>
  );
};

export default CommonCta;
