import React from "react";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import "./thank.scss";

//images
import { orangecta, received } from "../../images";

const Thank_received = () => {
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <div className="my_container">
          <div className="thank_container">
            <div>
              <img className="received_img" src={received} alt="" />
            </div>

            <div>
              <h1 className="received_title_text">THANK YOU</h1>
              <p className="received-text">
                Your message has been received, we will update you shortly.
              </p>
              <div className="cta_wrapper">
                <a href="/" className="right_arrow_cta_flex apply_hover received-btn ">
                  <span className="cta_text received-btn-text"> Back to Home </span>
                  <img
                    src={orangecta}
                    alt="Button arrow design"
                    className="cta_right_arrow"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <ScrollToTop />
    </>
  );
};
export default Thank_received;
