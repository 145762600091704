import React, { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import Banner from "../../components/Banner/Banner";
import CommonCta from "../../components/CommonCta/CommonCta";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { aboutUs, projectsRoute } from "../../helpers/constant-words";
import { locationicon, projectsbanner } from "../../images";
import "./projects.scss";

import { findAll as getAllProjects } from "../../Data/projects";
import { Link, useLocation, useParams,useNavigate  } from "react-router-dom";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

const Projects = (props) => {
  const projects = getAllProjects();
  const location = useLocation();
  let {category} = useParams();
  let navigate = useNavigate();
  const [projectIndex, setProjectIndex] = useState("ongoing");

  const handleTabChange = (status) => {
    setProjectIndex(status);
  };
  console.log({ props });
  useEffect(() => {
   

    // localStorage.setItem("activeCategory", location.state);
    // const defaultIndex = localStorage.getItem("activeCategory");
    // console.log("default", defaultIndex);
    // if (location.state !== 0) {
    //   setProjectIndex("completed");
    // } else {
    //   setProjectIndex("ongoing");
    // }

    setProjectIndex(category);


  }, [category]);
  const projectList = projects.map((item, i) => (
    <>
      <Fade duration="1000" triggerOnce>
        <div
          className={`col-lg-4 col-md-4 project_box ${
            projectIndex === item.status ? "active" : ""
          }`}
          key={i}
        >
          <Link to={`/projects/${item.status}/${item.slug}`}>
            <img
              loading="lazy"
              src={item.thumbnail.image}
              alt={item.thumbnail.alt}
            />
          </Link>
          <div className="text_container">
            <Link to={`/projects/${item.status}/${item.slug}`}>
              <h4 className="project-title">{item.title}</h4>
            </Link>
            <p className="project-desc">
              <img
                loading="lazy"
                className="location_icon"
                width={11.84}
                height={15.78}
                src={locationicon}
                alt="location icon"
              />
              {item.location}
            </p>
            <div className="cta_wrapper">
              <CommonCta
                ctaText="know More"
                linkTo={`/projects/${item.status}/${item.slug}`}
              />
            </div>
          </div>
        </div>
      </Fade>
    </>
  ));
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={projectsbanner}
          // caption="Ruby, Bandra(W)"
          caption="Jaswant Heights, Khar (W)"
          title="We believe and practice the saying - nothing but the best"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" direction="up" triggerOnce>
        <section className="projects_sec1">
          <div className="my_container">
            <div className="project_status_wrapper text-center">
              
              <div
                className={`status_tab ${
                  projectIndex === "ongoing" ? "active" : ""
                }`}
                onClick={() => navigate("/projects/ongoing")}
              >
                ONGOING PROJECTS
              </div>
             
             
              <div
                className={`status_tab ${
                  projectIndex === "completed" ? "active" : ""
                }`}
                onClick={() => navigate("/projects/completed")}
              >
                COMPLETED PROJECTS
              </div>
            </div>
            <div className="row project_flex">{projectList}</div>
          </div>
        </section>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
};

export default Projects;
