import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { homepage, privacypolicy } from "../../helpers/constant-words";
import "./privacypolicy.scss";

function Privacypolicy() {
  return (
    <>
      {/* <div className="new_breadcrumb_section">
    <div className="my_container">
        <div className="new_breadcrumb">
        <Link to={homepage}>Home</Link> |{" "}
        <Link to={privacypolicy}>Privacy-Policy</Link>
        </div>
    </div>
    </div> */}

      <div className="privacy-section">
        <div className="my_container">
          <div className="privacy-container">
            <h1 className="heading-text">Privacy Policy</h1>
            <p className="para-text">
              This Privacy Policy governs how L Nagpal Builders LLP obtains,
              manages, uses, maintains and discloses information collected from
              users (each, a ‘User’) of the
              <a
                href="https://www.lnagpalbuilders.com/"
                target="_blank"
                className="para-link"
              >
                {" "}
                www.lnagpalbuilders.com
              </a>{" "}
              website (‘Website’). This privacy policy applies to the Site and
              all products and services offered by L Nagpal Builders LLP or its
              sister concern companies, the Property Developers in and around
              Mumbai.
            </p>
            <div>
              <h2 className="sub-heading-text">
                PERSONAL IDENTIFICATION INFORMATION
              </h2>
              <p className="para-text">
                We may obtain personal identification information from Users in
                a variety of ways, including, but not limited to, when Users log
                into our website, register on the website, fill out a survey,
                and in connection with other tasks, activities, services,
                features or resources we make available on our website. Users
                may be asked to provide their name, phone number and email
                address. Users may, however, visit our website anonymously. We
                may collect personal identification information from Users only
                if they voluntarily give such information to us. Users can
                always refrain from giving personal identification information,
                except that it may prevent them from engaging in certain Site
                related activities.
              </p>
            </div>
            <div>
              <h2 className="sub-heading-text">
                NON-PERSONAL IDENTIFICATION INFORMATION
              </h2>
              <p className="para-text">
                We may collect non-personal identification information about
                users whenever they interact with our website. Non-personal
                identification information may include the name of the browser,
                the type of device and technical information about users means
                of connection to our site, such as the operating system and the
                internet service providers utilized and other similar
                information.
              </p>
            </div>
            <div>
              <h2 className="sub-heading-text">WEB BROWSER COOKIES</h2>
              <p className="para-text">
                Our website may use 'Cookies' to enhance the user experience.
                Users’ web browser places cookies on their hard drive for
                record-keeping purposes and sometimes to track information about
                them. Users' may choose to set their web browser to refuse
                cookies or to alert you when cookies are being sent. If they do
                so, note that some parts of the website may not function
                properly.
              </p>
            </div>
            <div>
              <h2 className="sub-heading-text">
                HOW WE USE COLLECTED INFORMATION
              </h2>
              <p className="para-text">
                L Nagpal Builders LLP may collect and use users personal
                information for the following purposes:
              </p>{" "}
              <br></br>
              <ul className="para-text privacy-list">
                <li>
                  To improve customer service - The Information you provide
                  helps us respond to your customer service needs and
                  requirements and support these more effectively.
                </li>
                <li>
                  To personalize user experience - We may use the information
                  provided to us to understand how our users’ as a group use the
                  services and resources provided on our website.
                </li>
                <li>
                  To improve our Site - We may use the feedback you provide us
                  with, to improve our products and services.
                </li>
                <li>
                  To send periodic emails we may use the email address to send
                  users information and updates pertaining to their
                  requirements/needs. It may also be used to respond to their
                  inquiries, questions, and/or other requests. If a user decides
                  to opt-in to our mailing list, they will receive emails that
                  may include company news, updates, marketing promotions, sales
                  adverts and other related product or service information, etc.
                  If at any time the user would like to unsubscribe from
                  receiving future emails, we include detailed unsubscribe
                  instructions at the bottom of each email.
                  <span className="list-para">
                    How we protect your information is that We adopt appropriate
                    data collection, storage and processing practices and
                    security measures to protect against unauthorized access,
                    alteration, disclosure or destruction of your personal
                    information, username, password, transaction information and
                    data stored on our website.
                  </span>
                </li>
              </ul>
            </div>

            <div>
              <h2 className="sub-heading-text">
                SHARING YOUR PERSONAL INFORMATION
              </h2>
              <p className="para-text">
                We do not sell, trade, or rent users' personal identification
                information to others/ any other third party. We may share
                generic aggregated demographic information not linked to any
                personal identification information regarding visitors and users
                with our business partners, trusted associates, partners and
                advertisers for the purposes outlined above.
              </p>
            </div>
            <div>
              <h2 className="sub-heading-text">SOCIAL MEDIA</h2>
              <p className="para-text">
                L Nagpal Builders operates channels, pages and accounts on some
                social media sites to inform, assist and engage with customers.
                L Nagpal Builders monitors and records comments and posts made
                on these channels about us in order to improve its products and
                services. Please note that you must not communicate to L Nagpal
                Builders through such social media sites the following
                information:
                <span className="list-para">
                  – sensitive Personal Data including (i) special categories of
                  Personal Data means any information revealing racial or ethnic
                  origin, political opinions, religious or philosophical
                  beliefs, or trade union membership, and the processing of
                  genetic data, biometric data for the purpose of uniquely
                  identifying a natural person, data concerning health or data
                  concerning a natural person’s sex life or sexual orientation
                  and (ii) other sensitive Personal Data such as criminal
                  convictions and offences and national identification number ;
                </span>
                <span className="list-para">
                  -Excessive, inappropriate, offensive or insulting information
                  towards individuals. L Nagpal Builders shall not be held
                  responsible for any information posted on those sites other
                  than the information posted by its employees on its behalf. We
                  shall be responsible only for our use of the personal data
                  received through such sites.
                </span>
              </p>
            </div>
            <div>
              <h2 className="sub-heading-text">
                CHANGES TO THIS PRIVACY POLICY
              </h2>
              <p className="para-text">
                L Nagpal Builders LLP has the right to update this privacy
                policy at any time. When we do, we will revise the updated date
                at the bottom of this page. We encourage users’ to frequently
                check this page for any changes to stay informed about how we
                are helping to protect the personal information we collect. You
                acknowledge and agree that it is your responsibility to review
                this privacy policy periodically and become aware of
                modifications.
              </p>
            </div>
            <div>
              <h2 className="sub-heading-text">
                YOUR ACCEPTANCE OF THESE TERMS
              </h2>
              <p className="para-text">
                By using this website, you signify your acceptance of this
                policy. If you do not agree to this policy, please do not use
                our website. Your continued use of the Site following the
                posting of changes to this policy will be deemed your acceptance
                of those changes.
              </p>
            </div>
            <div>
              <h2 className="sub-heading-text">CONTACT US</h2>
              <p className="para-text">
                If you have any questions about this Privacy Policy, the
                practices of this site, or your dealings with this site, please
                contact us at:{" "}
                <a className="" href="mailto:lnagpalbuilders@gmail.com">
                  lnagpalbuilders@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </>
  );
}

export default Privacypolicy;
