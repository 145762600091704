import React from "react";
import "./nri.scss";
import "swiper/css";
import { Accordion } from "react-bootstrap";

//components
import Banner from "../../components/Banner/Banner";
import { aboutUs } from "../../helpers/constant-words";

//images
import { faqopen, contactusbanner } from "../../images";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

function NRI() {
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={contactusbanner}
          caption="Ruby, Bandra(W)"
          title="Thriving in the heart of Mumbai"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" direction="up" triggerOnce>
        <div className="my_container">
          <div className="faqs_content">
            <div className="title">
              <h1>NRI CORNER</h1>
            </div>
            <div className="faqs">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p> Can NRIs buy real estate in India?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>
                      The Reserve Bank of India (RBI) has given general
                      permission to NRIs and PIOs to buy any residential or
                      commercial property in India.
                    </strong>{" "}
                    They can buy and sell both commercial and residential
                    properties in India.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>how many properties can NRI own in india?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>
                      There is no limit on the number of residential or
                      commercial properties that an NRI can purchase in India.
                    </strong>{" "}
                    Exception: An NRI however cannot buy agricultural land,
                    plantation land or a farmhouse in India.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      Documents needed by NRI for purchasing property in India?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="nri_faq_list">
                      <li>
                        <strong>Passport and/or OCI card:</strong> You will need
                        to show your Indian passport. If you hold a foreign
                        passport, you can buy property in India provided you
                        have a PIO (Persons of Indian Origin) card or an OCI
                        (Overseas Citizen of India) card.
                      </li>
                      <li>
                        <strong>PAN Card:</strong> This is mandatory for
                        property transactions.
                      </li>
                      <li>
                        Power of attorney: You need to also provide a{" "}
                        <strong>Power of attorney</strong>.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How can you execute a Power Of Attorney?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    A power of attorney authorizes another person residing in
                    India to complete transactions on your behalf. The POA will
                    need to be signed by you in the presence of a consulate
                    officer or notary in your country of residence. It will also
                    have to be attested by them.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How can you fund your purchase?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    You can fund your property purchases through inward
                    remittances or funds in a non-resident account. You can also
                    take a home loan.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Taxation Benefits for NRIs Under DTAA</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    NRIs can avoid paying double tax as per the Double Tax
                    Avoidance Agreement (DTAA). Usually, Non-Resident Indians
                    (NRI) live abroad, but earn income in India. In such cases,
                    it is possible that the income earned in India would attract
                    tax in India as well as in the country of the NRI’s
                    residence. This means that they would have to pay taxes
                    twice on the same income. As a measure to avoid this, the
                    Double Tax Avoidance Agreement (DTAA) was amended. The
                    agreement is signed to make a country an attractive
                    destination as well as to enable NRIs to get relief from
                    having to pay taxes multiple times. DTAA does not mean that
                    the NRI can completely avoid taxes, but it does mean that
                    the NRI can avoid paying higher taxes in both countries.
                    DTAA does allow an NRI to cut down on their tax implications
                    on the income earned in India. DTAA also reduces the
                    instances of tax evasion.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Income types under DTAA</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Under the Double Tax Avoidance Agreement, NRIs don’t have to
                    pay tax twice on the following income earned:
                    <br />
                    <br />
                    <ul className="nri_faq_list">
                      <li>Services provided in India.</li>
                      <li>Salary received in India.</li>
                      <li>House property located in India.</li>
                      <li>Capital gains on transfer of assets in India.</li>
                      <li>Fixed deposits in India.</li>
                      <li>Savings bank account in India.</li>
                    </ul>
                    <br />
                    If income from these sources is taxable in the NRI’s country
                    of residence, they can avoid paying taxes on it in India by
                    availing of the benefits of DTAA.
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="7">
                                <div className="div_line"></div>
                                <Accordion.Header><p>How can you fund your purchase ?</p>
                                    <img className="float-right icon" src={faqopen} alt="arrow icon"></img>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliex ec commodo consequat. Duis aute irure dolor inreprehenderit in voluptate velit esse cillum dolore eu fugiat.
                                </Accordion.Body>
                            </Accordion.Item> */}
              </Accordion>
            </div>
          </div>
        </div>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
}

export default NRI;
