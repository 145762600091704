import React from "react";
import "./faq.scss";
import "swiper/css";
import { Accordion } from "react-bootstrap";

//components
import Banner from "../../components/Banner/Banner";
import { aboutUs } from "../../helpers/constant-words";

//images
import { faqopen, commonban } from "../../images";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Fade } from "react-awesome-reveal";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

function Faq() {
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={commonban}
          caption="Ruby, Bandra(W)"
          title="An artistic dream turning real"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" direction="up" triggerOnce>
        <div className="my_container">
          <div className="faqs_content">
            <div className="title">
              <h1>FAQ'S</h1>
            </div>
            <div className="faqs">
              <Accordion defaultActiveKey="0">
                <p className="faq_title">FOR SOCIETY’S/LANDLORDS</p>
                <p className="segment">Segment 1 – REDEVELOPMENT</p>
                <Accordion.Item eventKey="0">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is Redevelopment?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    It is the process of reconstruction of a residential premise
                    by the demolition of the existing structure and construction
                    of a new one as per approvals from the Municipal Corporation
                    of Greater Mumbai (MCGM).
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is the meaning of FSI (Floor Space Index)?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    FSI is the maximum permissible floor area, that a builder
                    can build on a particular plot/piece of land. FSI is the
                    ratio of building floor covered area to area available on
                    the land.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is fungible FSI?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>
                      The additional space purchased from the authorities
                    </strong>{" "}
                    is referred to as fungible FSI. It is also referred to as
                    Premium FSI. As per the norm, the fungible FSI should not
                    exceed more than 35 percent of the floor area in residential
                    properties and 20 percent of the floor area in industrial
                    and commercial developments.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Are balconies and common spaces included in FSI?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    No, FSI only considers the gross floor area and hence
                    doesn’t include any balconies, exterior spaces, common areas
                    and driveways.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How can I buy FSI in Mumbai?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Paid FSI in Mumbai is available with the Planning Authority.
                    At present, Mumbai’s paid FSI charges are calculated
                    depending on the ready reckoner rates of a plot.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How is FSI calculated in Mumbai as per MCGM?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <table className="faq6_table">
                      <tr className="table_heading">
                        <th>Zone</th>
                        <th>Road Width</th>
                        <th colSpan="3">Island City</th>
                        <th>Suburbs</th>
                      </tr>
                      <tr className="table_subheading">
                        <td colSpan="2"></td>
                        <td>Pre DCPR</td>
                        <td>DCPR 2034</td>
                        <td className="islandchange">% Change</td>

                        <td>Pre DCPR</td>
                        <td>DCPR 2034</td>
                        <td>% Change</td>
                      </tr>
                      <tr>
                        <td rowspan="6">Residential/Commercial</td>
                      </tr>
                      <tr className="f-row">
                        <td>0-9m</td>
                        <td>1.7955</td>
                        <td>1.7955</td>
                        <td>0 %</td>

                        <td>2.03</td>
                        <td>1.35</td>
                        <td>-33%</td>
                      </tr>
                      <tr className="s-row">
                        <td>9m-12m</td>
                        <td>2.03</td>
                        <td>2.7</td>
                        <td>33%</td>

                        <td>2.7</td>
                        <td>2.7</td>
                        <td>0%</td>
                      </tr>
                      <tr className="t-row">
                        <td>12m-18m</td>
                        <td>2.3</td>
                        <td>3.24</td>
                        <td>41%</td>

                        <td>2.97</td>
                        <td>2.97</td>
                        <td>0%</td>
                      </tr>
                      <tr className="fo-row">
                        <td>18m-27m</td>
                        <td>2.57</td>
                        <td>3.645</td>
                        <td>42%</td>

                        <td>3.24</td>
                        <td>3.24</td>
                        <td>0%</td>
                      </tr>
                      <tr className="fi-row">
                        <td>&gt;27</td>
                        <td>2.7</td>
                        <td>4.05</td>
                        <td>50%</td>

                        <td>1</td>
                        <td>1</td>
                        <td>0%</td>
                      </tr>
                      <tr className="si-row">
                        <td colspan="2">Industrial</td>
                        <td>1</td>
                        <td>1</td>
                        <td>0%</td>

                        <td>1</td>
                        <td>1</td>
                        <td>0%</td>
                      </tr>
                    </table>
                    <br />
                    <p>
                      Note: The final FSI figures include Zonal Basic FSI,
                      Premium FSI, TDR & Fungible FSI
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      What Percentage of Society members need to agree to go for
                      Redevelopment?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>
                      REDUCING CONSENT CLAUSE FOR REDEVELOPMENT OF PROPERTY
                    </strong>
                    <p>
                      DCPR has reduced the consent criteria for redevelopment of
                      private housing societies and buildings under the
                      Maharashtra Housing and Area Development Authority
                      (MHADA):
                    </p>
                    <strong>70% </strong>members' consent - for private
                    co-operative housing societies.
                    <br />
                    <strong>51% </strong>members' consent - for land owned by
                    public authority/MHADA.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Does L Nagpal Builders do Self – Redevelopment?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, we do projects under Self-Redevelopment model wherein
                    we act as the Development Manager and execute the full
                    project from start to finish.
                  </Accordion.Body>
                </Accordion.Item>

                <p className="faq_title">FOR BUYERS/PURCHASERS</p>
                <p className="segment">SEGMENT 2 – RERA</p>
                <Accordion.Item eventKey="9">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is Real Estate Regulatory Authority (RERA)?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    RERA stands for Real Estate Regulatory Authority came into
                    existence as per the Real Estate (Regulation and
                    Development) Act, 2016 which aims to protect home purchasers
                    and also boost the real estate investments.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How Does RERA ensure the security of the Purchaser?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Under the RERA act, a minimum of 70% of the buyers’ and
                    investors’ money will be kept in a separate account. This
                    money will then be allotted to the builders only for
                    construction and land-related costs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How is Transparency maintained under RERA?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Builders are supposed to submit the original documents for
                    all projects they undertake. Builders are not supposed to
                    make any changes to the plans without the consent of the
                    buyer.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is the RERA carpet area?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Carpet area as per RERA-Real Estate Regulatory
                      Authority-is{" "}
                      <strong>
                        the net usable floor area of an apartment plus the
                        internal walls, which excludes the size of external
                        walls, services shafts, exclusive balcony or veranda
                        area and exclusive open terrace.
                      </strong>
                    </p>
                    <p>
                      <strong>Fairness:</strong> RERA has now instructed
                      developers to sell properties based on{" "}
                      <a
                        href="https://www.bankbazaar.com/home-loan/resources/carpet-area-vs-built-up-area-vs-super-built-up-area.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        carpet area
                      </a>{" "}
                      and not super built-up area. If the project has been
                      delayed, buyers are entitled to get back the entire money
                      invested or they can choose to be invested and receive
                      monthly investment on their money.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      Why is it considered necessary to register an Agreement
                      for Sale? What is the purpose of registration?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    The Registration Act, 1908, the Transfer of Property Act,
                    1882 and the Real Estate (Regulation and Development) Act,
                    2016 mandates the registration of an agreement for the sale
                    of immovable property. By registering the agreement for the
                    sale of immovable property, it becomes a permanent public
                    record. Further, a person is considered the legal owner of
                    an immovable property only after he gets such property
                    registered in his name.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How to Search for a RERA registered project?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Step 1. Log on to{" "}
                    <a
                      href="https://maharerait.mahaonline.gov.in/searchlist/search?MenuID=1069"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MahaRERA website
                    </a>{" "}
                    <br></br>
                    Step 2. Search for the desired project.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Want to know more about RERA?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <a
                      href="https://maharerait.mahaonline.gov.in/PDF/FAQMergedPDF.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MahaRERA Guidelines and FAQs
                    </a>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is ‘Capital Gain’?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>Capital gain</strong> refers to the profit that one
                    earns through the sale of a capital asset, such as real
                    estate, or even stocks or bonds. It is the difference
                    between the selling price of the property and its purchase
                    price. Depending on the period for which the property was
                    held, the capital gain can be either a{" "}
                    <strong>long-term capital gain</strong> or a{" "}
                    <strong>short-term capital gain.</strong>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What are ‘Short-Term Capital Gains’?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    When assets are bought and sold within a span of 24 months,
                    the beneficiary earning profit through the sale is liable to
                    pay short-term capital gains tax.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What is ‘Long-Term Capital Gain’?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Any immovable property held for more than two years is
                    treated as long-term and the profit on such sale is taxed at
                    20 per cent, plus cess and surcharge.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>How to save on LTCG tax?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ol className="faq20">
                      <li>
                        Section 54 exempts LTCG tax, arising on the sale of a
                        residential house, if the indexed capital gains are
                        invested in the purchase or construction of another
                        residential house, within the specified period.
                      </li>
                      <li>
                        Section 54F exempts LTCG tax, arising on the sale of any
                        asset other than a residential house, if the net sale
                        consideration is invested for the purchase/construction
                        of a house, within a specified period and subject to
                        fulfilment of certain other conditions.
                      </li>
                      <li>
                        Section 54EC allows an exemption of up to Rs 50 lakhs
                        from{" "}
                        <a href="https://housing.com/news/long-term-capital-gains-tax-property-5-things-must-know/">
                          LTCG tax
                        </a>{" "}
                        if the indexed capital gains are invested in
                        government-notified bonds, within six months.
                      </li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
                {/* </Accordion.Item>
              <Accordion.Item eventKey="21">
                <div className="div_line"></div>
                <Accordion.Header>
                  <p>What is Long Term CAPITAL GAIN?</p>
                  <img
                    className="float-right icon"
                    src={faqopen}
                    alt="arrow icon"
                  ></img>
                </Accordion.Header>
                <Accordion.Body>
                  Any immovable property held for more than two years, is
                  treated as long-term and the profit on such sale is taxed at
                  20 per cent, plus cess and surcharge.
                </Accordion.Body>
              </Accordion.Item> */}
                <Accordion.Item eventKey="22">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      Can I get an LTCG exemption in an under-construction
                      property?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    The main purpose of allowing exemptions u/s 54 is to
                    encourage individuals to buy or construct houses. However,
                    one of the conditions under this section is that if the
                    house is bought through capital gains to be built or is
                    under construction, the same must be completed within 3
                    years of the investment being made. This is applicable to
                    both a flat under construction on purchase and a house to be
                    built on a plot purchased.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="23">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What if my house is not built in 3 years?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <strong>
                      Case Where Construction Is Not Completed In Three Years:
                    </strong>
                    <br />
                    <br />
                    The Income Tax Act, in itself, is not very clear on the
                    stand to be taken if the construction of the house is not
                    completed in three years. Here, ‘house’ refers to the house
                    bought through the proceeds from capital gains. Legal
                    precedent has been set through several court cases on the
                    avail of LTCG exemption under Sections 54 and 54F even if
                    the house is not constructed completely within 3 years of
                    purchasing it.
                    <br />
                    <br />
                    <strong>Case Law:</strong> Commissioner of Income Tax vs.
                    Sardarmal and Shanthilal Kothari, 2008.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="disclaimer">
                <h5>DISCLAIMER</h5>
                <p>
                  These Frequently Asked Questions (FAQs) have been published
                  for general information purpose only. You are advised to take
                  specific legal/financial/tax advice on the above mentioned
                  matters. The terms and conditions for the purchase of the
                  apartment/unit/plot shall be mentioned in the final agreement
                  for sale executed between the purchaser and developer. The
                  FAQs mentioned above are subject to amendments/alterations by
                  the appropriate Government Authority from time to time and
                  interpretation by the Courts and concerned authorities. We
                  including our subsidiaries/affiliates/employees do not claim
                  any expertise in advice on the same. We shall not be
                  responsible for any reliance placed on the materials contained
                  herein.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
}

export default Faq;
