import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "./about.scss";
import "swiper/css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

//components
import { Fade } from "react-awesome-reveal";
import Banner from "../../components/Banner/Banner";
import { aboutUs } from "../../helpers/constant-words";

//images
import {
  info_building_img,
  quote,
  leftarrow,
  rightarrow,
  rightarrowbl,
  leftarrowbl,
  qualityicon,
  deliveryicon,
  transparencyicon,
  images,
  aboutusbanner,
} from "../../images";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { testimonials } from "../../Data/HomepageData";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

function About() {
  const { width } = useWindowDimensions();

  const team_members = [
    {
      image: images.Rajuteam.image,
      name: "Raju Nagpal",
      role: "Founding Partner",
      info: "The pioneer of construction in the family run business. At the age of 33 he decided to switch careers and focus his energy in real estate development. Having redeveloped several buildings in Mumbai, his expertise remains unmatched in terms of building planning, façade designing and architectural knowledge.",
    },

    {
      image: images.vinayakteam.image,
      name: "Vinayak Nagpal",
      role: "Partner",
      info: "He finished his Msc. In construction management from University of Reading in 2014 and decided to add to the family business’ growth. His professionalism has been a major driving force in the expansion of the company. His expertise lie in dealing with clients/society members, construction management and liasoning with all the professionals involved on the project.",
    },

    {
      image: images.Krishnateam.image,
      name: "Krishna Nagpal, CFA",
      role: "Partner",
      info: "Having completed all 3 levels of CFA in the first go, he’s currently pursuing his MBA from Schulich University, Canada. His charismatic approach in financial risk and management has driven his passion towards his final goal – MBA in Finance . His educational knowledge has been a bonus factor in the company’s growth.",
    },
  ];

  
  return (
    <>
      <div className="main_container">
        <Fade duration="2000" triggerOnce>
          <Banner
            img={aboutusbanner}
            caption="Ruby, Bandra (W)"
            title="Thriving in the heart of Mumbai"
            linkhead="Speak To Us Today"
            linkdesc="A call away from your dream home"
            linkurl={aboutUs}
          />
        </Fade>
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="heading_content">
            <div className="text">
              <h1 className="page_title">ABOUT US</h1>
            </div>
          </section>
        </Fade>
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="info_content">
            <div className="info_container">
              <div className="text">
                <div className="info info1">
                  <div className="title title1">
                    <h2>HISTORY & MILESTONES</h2>
                  </div>
                  <div className="description description1">
                    <p>
                      <b>L Nagpal Builders</b>, a sister concern company of L
                      Nagpal Developers, has engineered its way into the
                      construction business since 2001 to deliver the real
                      estate market with various projects we have completed with
                      a muscle-strong foundation, sound structure and
                      state-of-the-art elevations.
                      <br />
                      For over a decade, we have grown to be one of the suburbs'
                      largest corporations with assurances of top-notch
                      qualities, timely delivery, ethical business values & 100%
                      customer satisfaction. Working with our team, channel
                      partners, investors and customers, we are helping shape
                      the look of Mumbai’s skyline.
                    </p>
                    <p>
                      As builders of choice for many societies of suburbs, L
                      Nagpal Builders has consistently inspired confidence by
                      successfully managing redevelopment projects effortlessly.
                    </p>
                    <p>
                      Our mission is to deliver beyond expectations and create
                      superior-quality buildings and solid relationships with
                      our clients.
                    </p>
                  </div>
                </div>
                <div className="info info2">
                  <div className="title title2">
                    <h2>VISION</h2>
                  </div>
                  <div className="description description2">
                    <p>
                      With an ecstatic, dynamic and well-experienced team at{" "}
                      <strong>L Nagpal Builders, </strong> we understand and
                      value your family as one of ours. Our vision is to expand
                      in property development, Town planning and sustainable
                      construction in and around Mumbai.
                    </p>
                  </div>
                </div>
                <div className="key_features">
                  <div className="feature_title">
                    <h4>KEY FEATURES:</h4>
                  </div>
                  <div className="features_list">
                    <ul className="list">
                      <li>
                        <div className="feature_icon">
                          <img
                            src={qualityicon}
                            loading="lazy"
                            alt="quality"
                          ></img>
                        </div>
                        Quality & Workmanship
                      </li>
                      <li>
                        <div className="feature_icon">
                          <img
                            src={deliveryicon}
                            loading="lazy"
                            alt="delivery"
                          ></img>
                        </div>
                        Stipulated Delivery Period
                      </li>
                      <li>
                        <div className="feature_icon">
                          <img
                            src={transparencyicon}
                            loading="lazy"
                            alt="transparency"
                          ></img>
                        </div>
                        Transparency
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="image">
                <img
                  src={info_building_img}
                  loading="lazy"
                  alt="building"
                ></img>
                <div className="sticker">
                  <div className="text">
                    <div className="content">
                      <p>Since</p>
                      <h1>1998</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fade>
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="team_content">
            <div className="team_container">
              <div className="team_title">
                <h2>MEET THE TEAM</h2>
              </div>
              <div className="team_description">
                <p>
                  A family run business that strongly believes in its core
                  values of timely delivery, transparency and ethical business
                  practices.
                </p>
              </div>
              <div className="team_members">
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={85}
                  navigation={{
                    prevEl: ".arrows_wrapper .team_arrwleft",
                    nextEl: ".arrows_wrapper .team_arrwright",
                  }}
                  autoHeight={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      spaceBetween: 10,
                      slidesPerView: 3,
                    },
                  }}
                >
                  {team_members.map((data, i) => (
                    <SwiperSlide key={i}>
                      <div className="member_info">
                        <div className="member_info_content">
                          <div className="image">
                            <div className="image_content">
                              <img
                                src={data.image}
                                loading="lazy"
                                alt={data.name}
                              ></img>
                            </div>
                          </div>
                          <div className="text">
                            <h4>{data.name}</h4>
                            <h6>{data.role}</h6>
                            <p>{data.info}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              {width < 768 ? (
                <div className="arrows_wrapper team_arrow">
                  <img
                    src={leftarrowbl}
                    loading="lazy"
                    alt="teamarrwleft"
                    className="team_arw team_arrwleft"
                  />
                  <img
                    src={rightarrowbl}
                    loading="lazy"
                    alt="teamarrwright"
                    className="team_arw team_arrwright"
                  />
                </div>
              ) : null}
            </div>
          </section>
        </Fade>
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="testimony_content">
            <div className="testimony_container">
              <div className="testimony_info">
                <div className="testimony_title">
                  <h1>WHAT OUR CLIENTS SAY</h1>
                </div>
                <img src={quote} loading="lazy" alt="quote"></img>

                <div className="testimony_map">
                  <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    spaceBetween={20}
                    navigation={{
                      prevEl: " .leftarrowimg",
                      nextEl: " .rightarrowimg",
                    }}
                    autoHeight={true}
                  >
                    {testimonials.map((data, i) => (
                      <SwiperSlide key={i}>
                        <div className="testimony_data">
                          <div className="text">
                            <div className="testimony_description">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.description,
                                }}
                              ></p>
                            </div>
                          </div>

                          <div className="seperator">
                            <div className="horizontal_line"></div>
                          </div>

                          <div className="client_name">
                            <h6>{data.clientname}</h6>
                            <p>{data.role}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                {width > 600 ? (
                  <div className="arrow_container">
                    <div className="arrows_wrapper arrow_desktop">
                      <img
                        src={leftarrow}
                        loading="lazy"
                        alt="left arrow logo"
                        className="leftarrowimg"
                      />
                      <img
                        src={rightarrow}
                        loading="lazy"
                        alt="right arrow logo"
                        className="rightarrowimg"
                      />
                    </div>
                  </div>
                ) : null}

                {width < 600 ? (
                  <div className="arrows_wrapper arrow_mobile">
                    <img
                      src={leftarrow}
                      loading="lazy"
                      alt="left arrow logo"
                      className="leftarrowimg"
                    />
                    <img
                      src={rightarrow}
                      loading="lazy"
                      alt="right arrow logo"
                      className="rightarrowimg"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </Fade>
      </div>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
}

export default About;
