import React, { useEffect, useState } from "react";
import faqClose from "../../images/faqs/faqClose.svg";
import faqOpen from "../../images/faqs/faqOpen.svg";
import "./accordionitem.scss";

const AccordionItem = ({
  faqTitle,
  faqDesc,
  faqIndex,
  handleFaqIndexClick,
  faqActiveIndex,
}) => {
  const [faqActive, setFaqActive] = useState(false);
  useEffect(() => {
    if (!(faqActiveIndex === faqIndex)) {
      setFaqActive(false);
    }
  }, [faqActiveIndex]);

  const handleFaqClick = () => {
    setFaqActive(!faqActive);
  };
  return (
    <li
      className={`faq_item ${
        faqActiveIndex === faqIndex && faqActive ? "active" : null
      }`}
      onClick={() => {
        handleFaqIndexClick(faqIndex);
        handleFaqClick();
      }}
    >
      <h5 className="faq_title">{faqTitle}</h5>
      <p
        className={`faq_desc ${
          faqActiveIndex === faqIndex && faqActive ? "active" : null
        }`}
        dangerouslySetInnerHTML={{ __html: faqDesc }}
      ></p>
      <img
        src={faqActiveIndex === faqIndex && faqActive ? faqClose : faqOpen}
        alt="faq close and open"
        className="faq_open_close_logo"
      />
    </li>
  );
};

export default AccordionItem;
