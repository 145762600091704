import React, { useState, useEffect } from "react";
import "./projectinner.scss";
import { Fade } from "react-awesome-reveal";
import { homepage, projectsRoute } from "../../helpers/constant-words";
import {
  blackprev,
  blacknext,
  Downloadicon,
  play1,
  leftarrow,
  rightarrow,
} from "../../images";

import CommonCta from "../../components/CommonCta/CommonCta";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css/pagination";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AccordionItem from "../../components/AccordionItem/AccordionItem";
import { findBySlug as getSingleProject } from "../../Data/projects";

import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import EnquireForm from "../../components/EnquireForm/EnquireForm";
import { transform } from "lodash";

const ProjectInner = (props) => {
  const { pathname } = useLocation();
  const [toggler, setToggler] = useState(false);
  const [project, setProject] = useState({});
  const [projectIndex, setProjectIndex] = useState(0);
  const { slug: projectSlug } = useParams();
  const navigate = useNavigate();
  const [isBg, setIsbg] = useState(false);

  const { width } = useWindowDimensions();
  const [faqActiveIndex, setFaqActiveIndex] = useState(null);

  const galleryLength = project?.galleries?.length;
  const amenetiesLength = project?.amenities?.length;
  const floorplanLength = project?.floor_plans?.length;
  console.log("floorplanLength", floorplanLength);
  const areadetailLength = project?.area_details?.length;
  const virtualLength = project?.virtualTour?.length;
  const locationlenght = project?.locations?.length;

  const handleFaqIndexClick = (i) => {
    setFaqActiveIndex(i);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });
  const handleScroll = () => {
    // if (window.location.pathname === "/")
    if (window.pageYOffset > 60) {
      setIsbg(true);
    } else {
      setIsbg(false);
    }
  };

  const scrollToId = (_id) => {
    const element = document.getElementById(_id);
    const headerOffset = 210;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const projectTabList = project?.subNav?.map(({ tabName, _id }, i) => (
    <>
      <span
        key={i}
        onClick={() => {
          setProjectIndex(i);
          scrollToId(_id);
        }}
        className={`nav_link ${projectIndex === i ? "active" : ""} `}
      >
        {tabName}
      </span>
    </>
  ));

  const projectOverview = (
    <>
      <div className="col-lg-6">
        <div className="text_container">
          <h1 className="project_name">{project?.title}</h1>
          <p
            className="project_desc"
            dangerouslySetInnerHTML={{ __html: project?.description }}
          ></p>
          {project?.brochure !== "" && (
            <div className="cta_wrapper">
              <CommonCta
                anchorCtawhite
                anchorLink={project?.brochure}
                ctaText="E-brochure"
                download
                
              />
            </div>
          )}
          <div className="highlights_heading">Projects Highlights</div>

          {project?.highlights?.map((item, i) => {
            return (
              <div className="project_feature" key={i}>
                <p className="project_feature_content">
                  <img
                    className="location_icon"
                    width={21.43}
                    height={30.61}
                    src={item.icon}
                    alt="location icon"
                    loading="lazy"
                  />
                  <span className="project_feature_text">{item.title}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-lg-6">
        <div className="image_wrapper">
          <img
            width={726}
            height={1016}
            className="project_image"
            src={project?.image?.image}
            alt={project?.image?.alt}
            loading="lazy"
          />
          <div className="circl_wrapper">
            <img
              width={726}
              height={1016}
              className="project_image"
              src={project?.circle_text}
              alt="cricle"
              loading="lazy"
            />
            <div className="circul_text"></div>
          </div>
        </div>
      </div>
    </>
  );

  const galleryList = project?.galleries?.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="image_wrapper">
        <div className="imageanimate">
          <img
            className="gallery_img"
            src={item.thumbnail.image}
            alt="gallery"
            loading="lazy"
          />
        </div>
        <div className="title_text">{item.caption}</div>
      </div>
    </SwiperSlide>
  ));
  const onGoingProjList = project?.amenities?.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="row ongoing_box">
        <div className="col-lg-6">
          <div className="ongoing_proj_text">
            <div className="count">
              {i + 1 < 10 ? "0" + (i + 1) : i + 1}&nbsp;/&nbsp;
              {project?.amenities?.length < 10
                ? "0" + project?.amenities?.length
                : project?.amenities?.length}
            </div>
            <h5 className="ongoing_heading">{project?.amenities_title}</h5>
            <p className="ongoing_desc">{project?.amenities_desc}</p>
            <h6 className="ongoing_name">
              <img
                loading="lazy"
                className="amenity_icon"
                src={item.icon}
                alt="amenity"
              />
              {item.title}
            </h6>
            {/* <p className="ongoing_location">
                <div className="location_wrapper">
                  <img
                    loading="lazy"
                    className="location_icon"
                    src={item.icon}
                  />
                </div>
                Location : {item.location}
              </p>
              <p className="ongoing_area">
                <div className="area_wrapper">
                  <img loading="lazy" className="area_icon" src={areaicon} />
                </div>
                Area : {item.area}
              </p>
              <p className="ongoing_space">
                <div className="space_wrapper">
                  <img loading="lazy" className="space_icon" src={spaceicon} />
                </div>
                {item.space}
              </p> */}
            <p
              className="ongoing_desc"
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="image_container">
            <img
              // loading="lazy"
              className="ongoing_project"
              width={1078}
              height={825}
              src={item.image.image}
              alt={item.image.alt}
            />
          </div>
        </div>
      </div>
    </SwiperSlide>
  ));

  const featureList = project?.locations?.map((item, i) => (
    <AccordionItem
      faqTitle={item.title}
      faqDesc={item.description}
      key={i}
      faqIndex={i + 1}
      handleFaqIndexClick={handleFaqIndexClick}
      faqActiveIndex={faqActiveIndex}
    />
  ));

  const plansList = project?.floor_plans?.map((item, i) => (
    <SwiperSlide key={i}>
      <div className= {`image_wrapper ${floorplanLength < 2 ? "active" : ""} `} >
      {/* <div className= {floorplanLength >= 2 ? ("image_wrapper") : ("image_wrapper_2") } > */}
        <div className="imageanimate">
          {/* <a href={item.file} download> */}
          <img
            className="floor_img"
            src={item.thumbnail.image}
            alt={item.thumbnail.alt}
            loading="lazy"
          />
          {/* </a> */}
        </div>
        <a href={item.file} download target="_blank" rel="noreferrer">
          <img
            className="download_icon"
            src={Downloadicon}
            alt="floor"
            loading="lazy"
          />
        </a>
        <div
          className="image_title"
          dangerouslySetInnerHTML={{ __html: item.title }}
        ></div>
      </div>
    </SwiperSlide>
  ));

  const virtualList = project?.virtualTour?.map((item, i) => (
    <div className="col-md-6 virtual_col" key={i}>
      <img
        className="video_thumbnail"
        src={item.thumbnail}
        alt="video thumbnail"
        loading="lazy"
        width="841px"
        height="543px"
        onClick={() => setToggler(!toggler)}
      />
      <a href={item.link} rel="noreferrer" target="_blank">
        <img
          className="play_icon1"
          src={play1}
          alt="play icon"
          loading="lazy"
          onClick={() => setToggler(!toggler)}
        />
      </a>
      <p className="virtual_text">{item.title}</p>
    </div>
  ));

  useEffect(() => {
    const projectData = getSingleProject(projectSlug);

    if (!projectData) {
      navigate("/404");
    } else {
      setProject(projectData);
    }
  }, [projectSlug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {/* <Banner
        img={commonban}
        caption="Ruby, Bandra(W)"
        title="Urban living redefined, An artistic dream turning real"
        linkhead="Speak To Us Today"
        linkdesc="Project inner link to visit"
        linkurl={contactUs}
      /> */}

      <Fade duration="2000" triggerOnce>
        <section className="inner_sec1" id="one">
          <div className="my_container">
            <div className="my_breadcrumb">
              <Link to={homepage}>Home</Link> |{" "}
              <Link to={`/projects/${project?.status}`}>Projects</Link> |{" "}
              <Link
                to={`/projects/${project?.status}`}
               
                className="status-text"
              >
                {" "}
                {project?.status} Projects | {project?.title}
              </Link>
            </div>
            <div className="navigate_tabs">
              <div className="nav_wrapper">{projectTabList}</div>
            </div>
          </div>
        </section>
      </Fade>
      <Fade duration="2000" triggerOnce>
        <section className="inner_sec2">
          <div className="my_container">
            <div className="row">{projectOverview}</div>
          </div>
        </section>
      </Fade>
      {galleryLength !== 0 ? (
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="inner_sec3">
          {width < 767 ? (
              <h4 className="gallery_heading">Project gallery</h4>
              ) : null}
            <div className="gallery_container">
              {width > 767 ? (
              <h4 className="gallery_heading">Project gallery</h4>
              ) : null}
              <Swiper
                id="two"
                modules={[Pagination, Navigation, Autoplay]}
                navigation={{
                  prevEl: " .gallery-prev",
                  nextEl: " .gallery-next",
                }}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                className="mySwiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  600: {
                    slidesPerView: 1.1,
                    spaceBetween: 50,
                  },
                  1194: {
                    slidesPerView: 1.4,
                    spaceBetween: 75,
                  },
                  1600: {
                    slidesPerView: 1.4,
                    spaceBetween: 95,
                  },
                }}
              >
                {galleryList}
              </Swiper>
              <div className="arrows_wrapper">
                <img
                  src={blackprev}
                  alt="left arrow logo"
                  className="leftarrowimg gallery-prev"
                />
                <img
                  src={blacknext}
                  alt="right arrow logo"
                  className="rightarrowimg gallery-next"
                />
              </div>
            </div>
          </section>
        </Fade>
      ) : null}
      {amenetiesLength !== 0 ? (
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="inner_sec4">
            <div className="ongoing_project_container">
              <Swiper
                id="three"
                modules={[Navigation]}
                slidesPerView={1}
                spaceBetween={10}
                navigation={{
                  prevEl: ".ongoing-prev",
                  nextEl: ".ongoing-next",
                }}
                autoHeight={true}
              >
                {onGoingProjList}
              </Swiper>
            </div>
            <div className="arrow_container">
              <div className="arrows_wrapper arrow_desktop">
                <img
                  loading="lazy"
                  src={blackprev}
                  alt="left arrow logo"
                  className="leftarrowimg ongoing-prev"
                />
                <img
                  loading="lazy"
                  src={blacknext}
                  alt="right arrow logo"
                  className="ongoing-next"
                />
              </div>
            </div>
            {width < 600 ? (
              <div className="cta_wrapper">
                <CommonCta normalCta ctaText="View All" />
              </div>
            ) : null}
          </section>
        </Fade>
      ) : null}

      {locationlenght !== 0 ? (
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="inner_sec5">
            <div className="my_container">
              <h4 className="location_heading">NEARBY LOCATIONS</h4>
              <div className="row flex-center">
                <div className="col-lg-7">
                  <div className="map_frame" id="four">
                    <iframe
                      title="google map"
                      src={project?.map_link}
                      style={{ border: "0" }}
                      allowFullScreen=""
                      loading="lazy"
                      className="map"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="accordion_wrapper">{featureList}</div>
                </div>
              </div>
            </div>
          </section>
        </Fade>
      ) : null}

      {floorplanLength !== 0 ? (
        <Fade duration="2000" direction="up" triggerOnce>
          <section className={`inner_sec6 ${floorplanLength < 2 ? "active" : ""}`}>
            <div className="gallery_container">
              <h4 className="floor_heading">Floor Plans</h4>
              <div className="row" id="five">
                <div className="col-lg-3">
                  {/* {areadetailLength !== 0 ? ( */}
                  <div className="text_container">
                    <h5 className="area_details">Area Details</h5>
                    {project?.area_details?.map((item, i) => {
                      return (
                        <div className="area_wrap" key={i}>
                          <p className="title">{item.title}</p>
                          <p
                            className="area"
                            dangerouslySetInnerHTML={{ __html: item.area }}
                          ></p>
                        </div>
                      );
                    })}
                  </div>
                  {/* ) : null} */}
                </div>
                <div className="col-lg-9">
                  <div className="floor_slider">
                    <Swiper
                      modules={[Pagination, Navigation]}
                      navigation={{
                        prevEl: " .floor-prev",
                        nextEl: " .floor-next",
                      }}
                      // loop={true}
                      // autoplay={{
                      //   delay: 3000,
                      //   disableOnInteraction: true,
                      // }}
                      className="mySwiper"
                      breakpoints={{
                        0: {
                          slidesPerView: 1.1,
                          spaceBetween: 10,
                        },
                        600: {
                          slidesPerView: 1.1,
                          spaceBetween: 20,
                        },
                        850: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        1194: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },
                        1600: {
                          slidesPerView: 2,
                          spaceBetween: 50,
                        },
                      }}
                    >
                      {plansList}
                    </Swiper>
                    <div
                      className={`arrows_wrapper ${
                        width > 850
                          ? floorplanLength > 2
                            ? "active"
                            : ""
                          : floorplanLength > 1
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={leftarrow}
                        alt="left arrow logo"
                        className="leftarrowimg floor-prev"
                      />
                      <img
                        src={rightarrow}
                        alt="right arrow logo"
                        className="rightarrowimg floor-next"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fade>
      ) : null}

      {virtualLength !== 0 ? (
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="inner_sec7" id="six">
            <div className="virutal_container">
              <h4 className="virtual_heading">Virtual Tour <span className="virtual_heading_1">(Furniture Layout)</span></h4>
              <div className="row virtual_row">{virtualList}</div>
            </div>
          </section>
        </Fade>
      ) : null}
      {/* <ScrollToTop /> */}

      {/* <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/HHjSdy9l7Kc"]}
      /> */}
    <EnquireForm />
    </>
  );
};

export default ProjectInner;
