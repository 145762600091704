import React from "react";
import "./buyersguide.scss";

//components
import Banner from "../../components/Banner/Banner";
import CommonCta from "../../components/CommonCta/CommonCta";
import { aboutUs } from "../../helpers/constant-words";

//images
import {
  legalimg,
  loanimg,
  documentsimg,
  nricornerimg,
  buyersguidebanner,
} from "../../images";

//pages url
import {
  legal,
  homeloans,
  documentsandstages,
  nri,
} from "../../helpers/constant-words";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

function BuyersGuide() {
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={buyersguidebanner}
          // caption="Fifth Avenue Apartment, 5th Road JVPD"
          title="Reimagined fine living"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" direction="up" triggerOnce>
        <div className="my_container">
          <div className="title">
            <h1>BUYER’S GUIDE</h1>
          </div>

          <div className="guide_content">
            <div className="guide">
              <div className="image">
                <img src={legalimg} loading="lazy" alt="legal"></img>
              </div>
              <div className="guide_name">
                <p className="guide_text">LEGAL</p>
              </div>
              <div className="cta_wrapper">
                <CommonCta normalCta anchorLink={legal} ctaText="Know More" />
              </div>
            </div>
            <div className="guide">
              <div className="image">
                <img src={loanimg} loading="lazy" alt="loan"></img>
              </div>
              <div className="guide_name">
                <p className="guide_text">HOME LOANS</p>
              </div>
              <div className="cta_wrapper">
                <CommonCta
                  normalCta
                  anchorLink={homeloans}
                  ctaText="Know More"
                />
              </div>
            </div>
            <div className="guide">
              <div className="image">
                <img src={documentsimg} loading="lazy" alt="document"></img>
              </div>
              <div className="guide_name">
                <p className="guide_text">DOCUMENTS AND STAGES</p>
              </div>
              <div className="cta_wrapper">
                <CommonCta
                  normalCta
                  anchorLink={documentsandstages}
                  ctaText="Know More"
                />
              </div>
            </div>
            <div className="guide">
              <div className="image">
                <img src={nricornerimg} loading="lazy" alt="nri"></img>
              </div>
              <div className="guide_name">
                <p className="guide_text">NRI CORNER</p>
              </div>
              <div className="cta_wrapper">
                <CommonCta normalCta anchorLink={nri} ctaText="Know More" />
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
}

export default BuyersGuide;
