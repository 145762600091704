import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";

import { orangecta, dropdownarrow } from "../../images";

import "./enquireform.scss";

import { useNavigate } from "react-router-dom";
import config from "../../config/config";
import Joi from "joi";
import http from "../../helpers/http";

const EnquireForm = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");

  let base_url = config.api_url + "/forms/enquire";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Contact Number"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.document) {
      if (values.document.size > sizeLimit * 1024 * 1024) {
        errors["document"] = `File needs to be under ${sizeLimit} MB`;
      }
      let allowedExt = [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-office",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/pdf",
        "application/msword",
        "application/vnd.ms-office",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedExt.includes(values.document.type)) {
        errors["document"] = "Please upload valid file type";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    // let formData = new FormData();
    // for (const [key, data] of Object.entries(
    //   _.omit(values, ["document", "phone"])
    // )) {
    //   formData.append(key, data || "");
    // }

    // if (values.phone) {
    //   formData.append("phone", `${countryCode}${values.phone}`);
    // }
    // if (values.document) {
    //   formData.append("document", values.document);
    // }

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, {
      ...values,
      phone: `${countryCode}${values.phone}`,
    });

    if (data) {
      // redirect 
      // navigate("/thank-you");
      setValues({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const fetchCodes = async () => {
    const data = await http.get(`${config.api_url}/general/countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data.data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  return (
    <>
      <Fade duration="2000" triggerOnce>
        <section className="form_section">
          <div className="my_container">
            <h4 className="form_heading">ENQUIRE NOW</h4>
            <form onSubmit={handleSubmit}>
              <div className="form_flex">
                <div className="form_field_wrapper form_field_customwidth">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Name*"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.name}</p>
                </div>
                <div className="form_field_wrapper form_field_customwidth">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Email Id*"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.email}</p>
                </div>
                {/* Mobile no box here */}
                <div className="mobile_number_box form_field_wrapper form_field_customwidth">
                  <div className="country_code">
                    <select
                      className="form_input countrycode_select"
                      name="country_code"
                      value={countryCode}
                      onChange={(e) => setcountryCode(e.target.value)}
                    >
                      {codes.map((code, i) => {
                        return (
                          <option value={`+${code}`} key={i}>
                            {`+${code}`}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      className="dropwdown_icon"
                      width={16}
                      height={8}
                      loading="lazy"
                      alt="dropdown icon"
                      src={dropdownarrow}
                    />
                    <p className="error_message"></p>
                  </div>
                  <div className="mobile_number">
                    <input
                      type="tel"
                      className="form_input"
                      placeholder="Contact number*"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                    <p className="error_message error_message_1">{formError.phone}</p>
                  </div>
                </div>
                {/* Mobile no box end here */}
                {/* <div className="form_field_wrapper form_field_customwidth">
                  <input
                    type="tel"
                    className="form_input"
                    placeholder="Contact*"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.phone}</p>
                </div> */}
                <div className="form_field_wrapper form_field_textarea">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.message}</p>
                </div>
                <div className="form_field_wrapper form_field_customwidth_submit_cta">
                  <div className="cta_wrapper">
                    <button className="project-button">
                      {loading ? "Loading.." : "Submit"}
                      <img
                        src={orangecta}
                        alt="Button arrow design"
                        className="right-arrow cta_right_arrow"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Fade>
    </>
  );
};

export default EnquireForm;
