import React from "react";
import "./banner.scss";
import { rightarroworange } from "../../images";
import { Link, useLocation } from "react-router-dom";
import "animate.css";
import { contactUs } from "../../helpers/constant-words";

const Banner = ({ img, caption, title, linkhead, linkdesc, linkurl }) => {
  const { pathname } = useLocation();
  return (
    <>
      <main className="common-banner">
        <div className="banner-background-wrapper">
          <img className="banner-img" src={img} alt="banner-img" />
          <div className="speaktous">
            {pathname !== contactUs && (
              <>
                <Link to="/contact-us">
                  <div className="banner-link-wrapper">
                    <div className="banner-text-container">
                      <h3 className="banner-link-heading section_subheading">
                        {linkhead}
                      </h3>
                      <p className="banner-link-desc section_desc">
                        {linkdesc}
                      </p>
                    </div>
                    {/* <Link to={linkurl}> */}
                    <img
                      className="arrow-img"
                      src={rightarroworange}
                      alt="banner-img"
                    />
                    {/* </Link> */}
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="my_container">
          <div className="banner-text-wrapper">
            {/* {BannerData.map((item, i) => (
              <> */}
            <p className="banner-caption animate__animated animate__fadeInUp">
              {caption}
            </p>
            <h2 className="banner-title animate__animated animate__fadeInUp">
              {title}
            </h2>
            {/* </>
            ))} */}
          </div>
        </div>
      </main>
    </>
  );
};

export default Banner;
