export { default as logo } from "./general/Group-404.svg";
export { default as logogrey } from "./general/logo-gray.svg";
export { default as contactfooter } from "./general/contact-icon.svg";
export { default as emailfooter } from "./general/email-icon.svg";
export { default as youtubefooter } from "./general/youtube-icon.svg";
export { default as facebookfooter } from "./general/facebook-icon.svg";
export { default as linkedinfooter } from "./general/linkedin-icon.svg";
export { default as twitterfooter } from "./general/twitter-icon.svg";
export { default as instagramfooter } from "./general/instagram.svg";
export { default as rightarroworange } from "./general/rightarrow-orange.svg";
export { default as whatsapp } from "./general/whatsapp.svg";
export { default as mobileenquire } from "./general/enquirebackground.svg";
export { default as enquirewhatsapp } from "./general/whatsapp mobile (2).svg";
export { default as leftarroworange } from "./general/left-arrow-orange.svg";
export { default as blackprev } from "./general/black-prev.svg";
export { default as blacknext } from "./general/black-next.svg";
export { default as dropdownarrow } from "./general/Polygon 11.svg";
export { default as uploadicon } from "./general/Group 484.svg";
export { default as downarroww } from "./general/down-arrow-w.svg";
export { default as downarrowb } from "./general/black-arrow.svg";
export { default as Downloadicon } from "./general/Download-icon.png";

// about page
export { default as info_building_img } from "./About/info_building_img.jpg";
export { default as team_member_image } from "./About/team_member.svg";
export { default as quote } from "./About/quote.svg";
export { default as leftarrow } from "./About/Arrow_left.svg";
export { default as rightarrow } from "./About/Arrow_right.svg";
export { default as rightarrowbl } from "./About/right_arrow_bl.svg";
export { default as leftarrowbl } from "./About/left_arrow_bl.svg";
export { default as qualityicon } from "./About/quality.svg";
export { default as deliveryicon } from "./About/delivery.svg";
export { default as transparencyicon } from "./About/transparency.svg";

// Contact Page
export { default as contact_bg } from "./Contact/contact_background.png";
export { default as map } from "./Contact/map.svg";
export { default as location } from "./Contact/location.svg";
export { default as call } from "./Contact/call.svg";
export { default as mail } from "./Contact/mail.svg";

// Contact Page
export { default as partnerarrow } from "./partnerwithus/Group 583.svg";

// Buyers Guide Landing Page
export { default as legalimg } from "./BuyersGuideLanding/legal.jpg";
export { default as loanimg } from "./BuyersGuideLanding/home-loans.jpg";
export { default as documentsimg } from "./BuyersGuideLanding/documents.jpg";
export { default as nricornerimg } from "./BuyersGuideLanding/nricorner.jpg";

// Legal Buyers Guide Page
export { default as faqopen } from "./faqs/faqOpen.svg";
export { default as faqclose } from "./faqs/faqClose.svg";

//Homepage images
export { default as milestoneline } from "./Homepage/milestone-line.svg";
export { default as milestoneextraline } from "./Homepage/milestone-extra-line.svg";
export { default as locationicon } from "./Homepage/location-pin.svg";
export { default as feature1 } from "./Homepage/Group-3475.svg";
export { default as feature2 } from "./Homepage/Group-526.svg";
export { default as feature3 } from "./Homepage/Group-3476.svg";
export { default as locationiconhome } from "./Homepage/location-icon.svg";
export { default as areaicon } from "./Homepage/area-icon.svg";
export { default as spaceicon } from "./Homepage/space-icon.svg";
export { default as orangecta } from "./Homepage/cta_orange.svg";
export { default as whitearrowcta } from "./Homepage/whiteRightArrow.svg";

// banner images
export { default as commonban } from "./banner/Mask-Group-16.jpg";
export { default as aboutusbanner } from "./banner/ruby_2.jpg";
export { default as projectsbanner } from "./banner/jaswant_height_3.jpg";
export { default as partnerbanner } from "./banner/Partners-with-us.jpg";
export { default as buyersguidebanner } from "./banner/buyers-guide.jpg";
export { default as buyersguiddocument } from "./banner/Document.jpg";
export { default as contactusbanner } from "./banner/ruby_3.jpg";
export { default as blankPDF } from "./ProjectInner/Brochures/demo_pdf.pdf";
export { default as projectlocation } from "./ProjectInner/location-icon.svg";
export { default as residenceicon } from "./ProjectInner/residence-icon.svg";
export { default as yearscompletion } from "./ProjectInner/years-completion-icon.svg";
export { default as nntowerpdf } from "./ProjectInner/nn-tower/E-BROCHURE.pdf";
export { default as bhagirathipdf } from "./ProjectInner/BhagirathiSadan/BHAGIRATHI.pdf";
export { default as hirapdf } from "./ProjectInner/Hira/HIRA.pdf";
export { default as propertytypeicon } from "./ProjectInner/property-type-icon.svg";
export { default as leReveBrochour } from "./ProjectInner/le-reve/LE RÊVE - EBROCHURE (1).pdf";
export { default as satnamleelabrochur } from "./ProjectInner/satnam-leela/Satnam_Leela_brochur.pdf";

// header images
export { default as rightarw } from "./header/rightarw.svg";
export { default as downarw } from "./header/downarw.svg";
export { default as submenuarw } from "./header/submenuarw.svg";

// footer images
export { default as callblack } from "./general/call.svg";
export { default as mailblack } from "./general/email.svg";

//Thank images
export { default as received } from "./Thank/received.png";

// virtual tour
export { default as play1 } from "./ProjectInner/play_1.svg";

export let images = {
  //bhagarathi sadan images
  ocreceived: {
    image: require("./ProjectInner/Oc-recieved.png"),
    alt: "OC Received",
  },
  completed: {
    image: require("./ProjectInner/Complete_OC.png"),
    alt: "under construction",
  },
  underconstruction: {
    image: require("./ProjectInner/Under_Construction.png"),
    alt: "under construction",
  },
  RERAnoP51800050072: {
    image: require("./ProjectInner/Rera-no-P51800050072.png"),
    alt: "RERA no P51800050072",
  },
  RERAnoP51800048002: {
    image: require("./ProjectInner/RERA-No-P51800048002.png"),
    alt: "RERA no P51800048002",
  },
  RERAnoP51800031276: {
    image: require("./ProjectInner/RERA-No-P51800031276.png"),
    alt: "RERA no P51800031276",
  },
  RERAnoP51900045357: {
    image: require("./ProjectInner/RERA-No-P51900045357.png"),
    alt: "RERA no P51900045357",
  },
  RERAnoP51800033235: {
    image: require("./ProjectInner/RERA-No-P51800033235.png"),
    alt: "RERA no P51800033235",
  },
  phpalmgallery1: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-living-1.jpg"),
    alt: "Lobby",
  },
  phpalmgallery2: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-living-2.jpg"),
    alt: "Lobby",
  },
  phpalmgallery3: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-living-3.jpg"),
    alt: "Lobby",
  },
  phpalmgallery4: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-living-4.jpg"),
    alt: "Lobby",
  },
  phpalmgallery5: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-living-5.jpg"),
    alt: "Lobby",
  },
  phpalmgallery6: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-room-1.jpg"),
    alt: "Lobby",
  },
  phpalmgallery7: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-room-2.jpg"),
    alt: "Lobby",
  },
  phpalmgallery8: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-room-3.jpg"),
    alt: "Lobby",
  },
  phpalmgallery9: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-room-4.jpg"),
    alt: "Lobby",
  },
  phpalmgallery10: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-study-room-1.jpg"),
    alt: "Lobby",
  },
  phpalmgallery11: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-study-room-2.jpg"),
    alt: "Lobby",
  },
  phpalmgallery12: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-bathroom-1.jpg"),
    alt: "Lobby",
  },
  phpalmgallery13: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-bathroom-2.jpg"),
    alt: "Lobby",
  },
  phpalmgallery14: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-5.jpg"),
    alt: "Lobby",
  },
  phpalmgallery15: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-19.jpg"),
    alt: "Lobby",
  },
  phpalmgallery16: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-20.jpg"),
    alt: "Lobby",
  },
  phpalmgallery17: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-21.jpg"),
    alt: "Lobby",
  },
  phpalmgallery18: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-1.jpg"),
    alt: "Lobby",
  },
  phpalmgallery19: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-2.jpg"),
    alt: "Lobby",
  },
  phpalmgallery20: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-3.jpg"),
    alt: "Lobby",
  },
  phpalmgallery21: {
    image: require("./ProjectInner/PH-Palm/gallery/ph-palm-gallery-4.jpg"),
    alt: "Lobby",
  },
  bhagirathiamenity1: {
    image: require("./ProjectInner/BhagirathiSadan/gymnasium.png"),
    alt: "Gymnasium",
  },
  bhagirathiamenity2: {
    image: require("./ProjectInner/BhagirathiSadan/society-office.png"),
    alt: "Society Office",
  },
  bhagirathigallery1: {
    image: require("./ProjectInner/BhagirathiSadan/gallery/bhagyrati-sadan-day.jpg"),
    alt: "bhagyrati-sadan-day",
  },
  bhagirathigallery2: {
    image: require("./ProjectInner/BhagirathiSadan/gallery/bhagyrati-sadan-night.jpg"),
    alt: "bhagyrati-sadan-night",
  },
  bhagirathiplan1: {
    image: require("./ProjectInner/BhagirathiSadan/Bhagirathi-Sadan-14th.jpg"),
    alt: "bhagyrati-sadan-night",
  },
  bhagirathiplan2: {
    image: require("./ProjectInner/BhagirathiSadan/Bhagirathi-Sadan-11-13.jpg"),
    alt: "bhagyrati-sadan-night",
  },
  bhagirathifloorplan1: {
    image: require("./ProjectInner/BhagirathiSadan/Bhagirathi_Sadan _14th_floor.pdf"),
    alt: "bhagyrati-sadan-night",
  },
  bhagirathifloorplan2: {
    image: require("./ProjectInner/BhagirathiSadan/Bhagirathi_Sadan-11_13_flr_plan.pdf"),
    alt: "bhagyrati-sadan-night",
  },
  hiraplan1: {
    image: require("./ProjectInner/Hira/7TH&9TH-FLOOR.jpg"),
    alt: "bhagyrati-sadan-night",
  },
  hiraplan2: {
    image: require("./ProjectInner/Hira/10TH-FLOOR.jpg"),
    alt: "bhagyrati-sadan-night",
  },
  hirafloorplan1: {
    image: require("./ProjectInner/Hira/7TH&9TH-FLOOR.pdf"),
    alt: "bhagyrati-sadan-night",
  },
  hirafloorplan2: {
    image: require("./ProjectInner/Hira/10TH-FLOOR.pdf"),
    alt: "bhagyrati-sadan-night",
  },
  fifthavenuegallery1: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-1.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery2: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-2.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery3: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-3.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery4: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-4.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery5: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-5.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery6: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-6.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery7: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-7.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery8: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-8.jpg"),
    alt: "5th avenue apartment gallery",
  },
  fifthavenuegallery9: {
    image: require("./ProjectInner/5thavenue/gallery/5th-avenue-apt-9.jpg"),
    alt: "5th avenue apartment gallery",
  },
  jaswantgallerynew1: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-1.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew2: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-2.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew3: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-3.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew4: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-4.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew5: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-5.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew6: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-6.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew7: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-7.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew8: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-8.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew9: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-9.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew10: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-10.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew11: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-11.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew12: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-12.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew13: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-13.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew14: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-14.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew15: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-15.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew16: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-16.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew17: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-17.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew18: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-18.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew19: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-19.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew20: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-20.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew21: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-21.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew22: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-22.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew23: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-23.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew24: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-24.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew25: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-25.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew26: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-26.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew27: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-27.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew28: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-28.jpg"),
    alt: "Jaswant Height gallery",
  },
  jaswantgallerynew29: {
    image: require("./ProjectInner/jaswant-heights/gallery/jaswant-new-resized_29.jpg"),
    alt: "Jaswant Height gallery",
  },
  rubygallery1: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-1.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery2: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-2.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery3: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-3.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery4: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-4.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery5: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-5.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery6: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-6.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery7: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-7.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery8: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-8.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery9: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-9.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery10: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-10.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery11: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-11.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery12: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-12.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery13: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-13.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery14: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-14.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery15: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-15.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery16: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-16.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery17: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-17.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery18: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-18.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery19: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-19.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery20: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-20.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery21: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-21.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery22: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-22.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery23: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-23.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery24: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-24.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery25: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-25.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery26: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-26.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery27: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-27.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery28: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-28.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery29: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-29.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery30: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-30.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery31: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-31.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery32: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-32.jpg"),
    alt: "Ruby Bandra gallery",
  },
  rubygallery33: {
    image: require("./ProjectInner/ruby/gallery/ruby-gallery-33.jpg"),
    alt: "Ruby Bandra gallery",
  },
  lerevegymnasium: {
    image: require("./ProjectInner/le-reve/Gymnasium.png"),
    alt: "Gymnasium",
  },
  Rooftoplounge: {
    image: require("./ProjectInner/le-reve/Rooftop-lounge.jpg"),
    alt: "Rooftop lounge",
  },
  Rooftoploungegazebo: {
    image: require("./ProjectInner/le-reve/Rooftop-lounge-gazebo.jpg"),
    alt: "Rooftop lounge gazebo",
  },
  lerevelobby: {
    image: require("./ProjectInner/le-reve/Lobby.png"),
    alt: "Lobby",
  },
  lereveRooftoploungedeck: {
    image: require("./ProjectInner/le-reve/Rooftop-lounge-deck.png"),
    alt: "Rooftop lounge deck",
  },
  lereveSocietyOffice: {
    image: require("./ProjectInner/le-reve/Society-Office12.png"),
    alt: "Society Office",
  },
  lerevegallery1: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-1.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery2: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-2.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery3: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-3.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery4: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-4.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery5: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-5.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery6: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-6.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery7: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-7.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery8: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-8.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery9: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-9.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery10: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-10.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery11: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-11.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery12: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-12.jpg"),
    alt: "le reve gallery",
  },
  lerevegallery13: {
    image: require("./ProjectInner/le-reve/gallery/le-reve-13.jpg"),
    alt: "le reve gallery",
  },
  lerevefloor1: {
    image: require("./ProjectInner/le-reve/floor/1bhk-le-reve-3.png"),
    alt: "le reve floor",
  },
  lerevefloor2: {
    image: require("./ProjectInner/le-reve/floor/2-3-bhk-le-reve-3.png"),
    alt: "le reve floor",
  },
  lerevefloor3: {
    image: require("./ProjectInner/le-reve/floor/10th-floor-penthouse-le-reve-3.png"),
    alt: "le reve floor",
  },
  nntowergallery1: {
    image: require("./ProjectInner/nn-tower/NN-Gallery_1.jpg"),
    alt: "Gallery Image 1",
  },
  nntowergallery2: {
    image: require("./ProjectInner/nn-tower/NN-Gallery_2.jpg"),
    alt: "Model009",
  },
  nntowergallery3: {
    image: require("./ProjectInner/nn-tower/NN-Gallery_3.jpg"),
    alt: "Nagpal developers",
  },
  nntowerGamesroom: {
    image: require("./ProjectInner/nn-tower/Games-room.png"),
    alt: "Games room",
  },
  nntowerGymnasiumSteam: {
    image: require("./ProjectInner/nn-tower/Gymnasium-with-Steam.png"),
    alt: "Gymnasium with Steam",
  },
  nntowerRooftoplounge: {
    image: require("./ProjectInner/nn-tower/Rooftop-Lounge.png"),
    alt: "Rooftop lounge",
  },
  nntowerlobby: {
    image: require("./ProjectInner/nn-tower/NN-Lobby.jpg"),
    alt: "Lobby",
  },
  nntowerSocietyOffice: {
    image: require("./ProjectInner/nn-tower/Society-Office.png"),
    alt: "Society Office",
  },
  satnamleelagallery1: {
    image: require("./ProjectInner/satnam-leela/86-chembur-changes.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery2: {
    image: require("./ProjectInner/satnam-leela/bathroom.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery3: {
    image: require("./ProjectInner/satnam-leela/bathroom1.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery4: {
    image: require("./ProjectInner/satnam-leela/bathroom2.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery5: {
    image: require("./ProjectInner/satnam-leela/BEDROOM1.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery6: {
    image: require("./ProjectInner/satnam-leela/BEDROOM2.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery7: {
    image: require("./ProjectInner/satnam-leela/living.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery8: {
    image: require("./ProjectInner/satnam-leela/living1.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery9: {
    image: require("./ProjectInner/satnam-leela/living2.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery10: {
    image: require("./ProjectInner/satnam-leela/LOBBY.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery11: {
    image: require("./ProjectInner/satnam-leela/LOBBY1.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery12: {
    image: require("./ProjectInner/satnam-leela/terrace.jpg"),
    alt: "Society Office", 
  },
  satnamleelagallery13: {
    image: require("./ProjectInner/satnam-leela/terrace5.jpg"),
    alt: "Society Office", 
  },
  satnamleelaamenity1: {
    image: require("./ProjectInner/satnam-leela/terrace5.jpg"),
    alt: "Society Office", 
  },
  satnamleelaamenities1: {
    image: require("./ProjectInner/satnam-leela/satnamleela-terrace.jpg"),
    alt: "Lounge", 
  },
  satnamleelaamenities2: {
    image: require("./ProjectInner/satnam-leela/satnamleela-lobby.jpg"),
    alt: "Lobby", 
  },
  Krishnateam: {
    image: require("./About/Krishna.png"),
    alt: "Krishna",
  },
  vinayakteam: {
    image: require("./About/vinayak.png"),
    alt: "vinayak",
  },
  Rajuteam: {
    image: require("./About/Raju.png"),
    alt: "Raju",
  },
  commonban1: {
    image: require("./banner/homepage-banner-new-1.jpg"),
    alt: "Jaswant Height",
  },
  commonban2: {
    image: require("./banner/homepage-banner-new-2.jpg"),
    alt: "Jaswant Height",
  },
  commonban3: {
    image: require("./banner/homepage-banner-new-3.jpg"),
    alt: "Ruby Bandra (W)",
  },
  commonban4: {
    image: require("./banner/homepage-banner-new-4.jpg"),
    alt: "Ruby Bandra (W)",
  },
  building1: {
    image: require("./Homepage/Mask-Group-19.jpg"),
    alt: "on site img",
  },
  building2: {
    image: require("./Homepage/etienne-girardet-sgYamIzhAhg-unsplash.jpg"),
    alt: "working img",
  },
  siteicon: {
    image: require("./Homepage/Group-3474.jpg"),
    alt: "site icon",
  },
  completedproject5thavenue: {
    image: require("./Projects/Completed/5TH-AVENUE-APARTMENTS.jpg"),
    alt: "5th Avenue",
  },
  completedprojectjaswant: {
    image: require("./Projects/Completed/JASWANT-HEIGHTS.jpg"),
    alt: "5th Avenue",
  },
  completedprojectruby: {
    image: require("./Projects/Completed/RUBY-new.jpg"),
    alt: "RUBY",
  },
  completedprojectvasantvihar: {
    image: require("./Projects/Completed/VASANT-VIHAR.jpg"),
    alt: "VASANT VIHAR",
  },
  completedprojectlaview: {
    image: require("./Projects/Completed/LA-VIEW.jpg"),
    alt: "LA VIEW",
  },
  completedprojectphhouse: {
    image: require("./Projects/Completed/PH-HOUSE-new.jpg"),
    alt: "PH HOUSE",
  },
  completedprojectsatnamsharan: {
    image: require("./Projects/Completed/SATNAM-SHARAN.jpg"),
    alt: "SATNAM SHARAN",
  },
  completedproject1: {
    image: require("./Projects/Completed/5thAvenue.jpg"),
    alt: "5th Avenue",
  },
  completedproject2: {
    image: require("./Projects/Completed/amiya-06.jpg"),
    alt: "amiya-06",
  },
  completedproject3: {
    image: require("./Projects/Completed/anupamafinal01-16-11-06.jpg"),
    alt: "anupama",
  },
  completedproject4: {
    image: require("./Projects/Completed/chhaya.jpg"),
    alt: "chhaya",
  },
  completedproject5: {
    image: require("./Projects/Completed/jaswant.jpg"),
    alt: "jaswant",
  },
  completedproject6: {
    image: require("./Projects/Completed/lavie.jpg"),
    alt: "lavie",
  },
  completedproject7: {
    image: require("./Projects/Completed/PH-House.jpg"),
    alt: "PH-House",
  },
  completedproject8: {
    image: require("./Projects/Completed/phvilla.jpg"),
    alt: "phvilla",
  },
  completedproject9: {
    image: require("./Projects/Completed/ruby.jpg"),
    alt: "ruby",
  },
  satnamleela: {
    image: require("./Projects/Completed/satnam_leela.png"),
    alt: "satnam",
  },
  completedproject11: {
    image: require("./Projects/Completed/sudhama_niwas_resized.jpg"),
    alt: "sudhama-niwas",
  },
  completedproject12: {
    image: require("./Projects/Completed/vasant_vihar.jpg"),
    alt: "vasant",
  },
  completedproject13: {
    image: require("./Projects/Completed/zahra-court.jpg"),
    alt: "zahra-court",
  },
  completedproject14: {
    image: require("./Projects/Completed/bhagat.jpg"),
    alt: "Bhagat",
  },
  completedproject15: {
    image: require("./Projects/Completed/uphaar-mandir.jpg"),
    alt: "uphaar-mandir",
  },
  completedproject17: {
    image: require("./Projects/Completed/ph_palm_resized.jpg"),
    alt: "uphaar-mandir",
  },
  completedproject18: {
    image: require("./Projects/Completed/Bhagwanti_heights.jpg"),
    alt: "uphaar-mandir",
  },
  completedproject19: {
    image: require("./Projects/Completed/chhaya_building.jpg_night.jpg"),
    alt: "uphaar-mandir",
  },
  ongoingproject1: {
    image: require("./Projects/Ongoing/bhagyrati-sadan-night.jpg"),
    alt: "bhagirathi",
  },
  ongoingproject2: {
    image: require("./Projects/Ongoing/HIRA.jpg"),
    alt: "HIRA",
  },
  ongoingproject3: {
    image: require("./Projects/Ongoing/nn-tower.jpg"),
    alt: "nn",
  },
  ongoingproject4: {
    image: require("./Projects/Ongoing/satnam.jpg"),
    alt: "satnam",
  },
  ongoingproject5: {
    image: require("./Projects/Ongoing/siddarth.jpg"),
    alt: "siddarth",
  },
  // ongoingproject6: {
  //   image: require("./Projects/Ongoing/Le-Reve.jpg"),
  //   alt: "siddarth",
  // },
  ongoingproject6: {
    image: require("./Projects/Ongoing/LE-REVE-1.jpg"),
    alt: "siddarth",
  },

  projectlist1: {
    image: require("./Projects/project1.png"),
    alt: "Ongoing project 1",
  },
  
  //featured images
  bhagirathifeatured: {
    image: require("./Projects/Ongoing/featured/bhagyrati_sadan_night_feat.jpg"),
    alt: "bhagyrati sadan featured",
  },
  hirafeatured: {
    image: require("./Projects/Ongoing/featured/HIRA_elevation_feat.jpg"),
    alt: "hira featured",
  },
  lerevefeatured: {
    // image: require("./Projects/Ongoing/featured/le-reve-featured-2.jpg"),
    image: require("./Projects/Ongoing/featured/le-reve1-featured.jpg"),
    alt: "le reve featured",
  },
  nntowerfeatured: {
    image: require("./Projects/Ongoing/featured/NN_feat.jpg"),
    alt: "nn tower featured",
  },
  satnamleelafeatured: {
    image: require("./Projects/Ongoing/featured/satnam_feat.jpg"),
    alt: "satnam leela featured",
  },
  amiyafeatured: {
    image: require("./Projects/Completed/featured/amiya-featured.jpg"),
    alt: "amiya featured",
  },
  anupamafeatured: {
    image: require("./Projects/Completed/featured/anupama-featured.jpg"),
    alt: "anupama featured",
  },
  bhagatfeatured: {
    image: require("./Projects/Completed/featured/bhagat-featured.jpg"),
    alt: "bhagat featured",
  },
  sudhamaniwasfeatured: {
    image: require("./Projects/Completed/featured/sudhama-niwas-featured.jpg"),
    alt: "sudhama niwas featured",
  },
  uphaarfeatured: {
    image: require("./Projects/Completed/featured/uphaar-featured.jpg"),
    alt: "uphaar featured",
  },
  zahracourtfeatured: {
    image: require("./Projects/Completed/featured/zahra-court-featured.jpg"),
    alt: "zahra court featured",
  },

  project_thumbnail: {
    image: "https://via.placeholder.com/490x620/333/333",
    alt: "project thumbnail",
  },
  project_image: {
    image: "https://via.placeholder.com/726x1016/333/333",
    alt: "project image",
  },
  project_featured_image: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project featured image",
  },
  project_gallery_1: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project gallery 1",
  },
  project_gallery_2: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project gallery 2",
  },
  project_amenities_1: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project amenities 1",
  },
  project_amenities_2: {
    image: "https://via.placeholder.com/1078x825/333/333",
    alt: "project amenities 2",
  },
  project_floor_1: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "project floor 1",
  },
  project_floor_2: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "project floor 2",
  },
  projectlistingplaceholder: {
    image: "https://via.placeholder.com/490x620/333/333",
    alt: "project listing placeholder",
  },
  floorplaceholder: {
    image: "https://via.placeholder.com/626x394/333/333",
    alt: "floor placeholder",
  },
  galleryplaceholder: {
    image: "https://via.placeholder.com/1074x825/333/333",
    alt: "gallery placeholder",
  },
  virtual_1: {
    image: require("./ProjectInner/virtual_1.png"),
    alt: "virtual_1",
  },
  virtual_2: {
    image: require("./ProjectInner/virtual_2.png"),
    alt: "virtual_2",
  },
};
