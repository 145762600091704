import React, { useState } from "react";

import Banner from "../../components/Banner/Banner";
import { Fade } from "react-awesome-reveal";
import { aboutUs } from "../../helpers/constant-words";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import {
  downarw,
  partnerarrow,
  partnerbanner,
  rightarw,
} from "../../images";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./channelpartners.scss";
import ChannelForm from "./Forms/ChannelPartner/ChannelForm";
import LandOwnerForm from "./Forms/LandOwnerForm/LandOwnerForm";
import RedevelopForm from "./Forms/RedevelopForm/RedevelopForm";
import VendorForm from "./Forms/VendorForm/VendorForm";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

const partners = [
  {
    id: 1,
    title: "Channel Partners",
  },
  {
    id: 2,
    title: "Land Owners",
  },
  {
    id: 3,
    title: "Redevelop your society",
  },
  {
    id: 4,
    title: "Vendors",
  },
];

const ChannelPartners = () => {
  const { width } = useWindowDimensions();
  const [partnerIndex, setPartnerIndex] = useState(0);
  const [mbIndex, setMbIndex] = useState(0);

  const categoryList = partners.map((nameData, i) => (
    <div
      className={`partner ${i === partnerIndex ? "partner_active" : ""}`}
      key={nameData.id}
      onClick={() => setPartnerIndex(i)}
    >
      {nameData.title}
      <img
        className={`orange_arrow ${i === partnerIndex ? "active" : ""}`}
        src={partnerarrow}
        alt="arrow"
      />
    </div>
  ));

  const mbCategoryList = partners.map((details, i) => (
    <div className="mb_categories_cont" key={i}>
      <div
        className={`mb_categories_accordion_title ${
          mbIndex === i ? "active" : null
        }`}
        onClick={() => {
          handleMbClick(i);
        }}
        id={`${i}`}
      >
        <span>{details.title}</span>
        <img
          src={mbIndex === i ? downarw : rightarw}
          alt=" dropdown arrow"
          loading="lazy"
          className={`mb_categories_down_arrow ${
            mbIndex === i ? "active" : null
          }`}
        />
      </div>
      <div
        className={`mb_categories_accordion_content ${
          mbIndex === i ? "active" : null
        }`}
      >
        {mbIndex === 0 ? <ChannelForm /> : ""}
        {mbIndex === 1 ? <LandOwnerForm /> : ""}
        {mbIndex === 2 ? <RedevelopForm /> : ""}
        {mbIndex === 3 ? <VendorForm /> : ""}
      </div>
    </div>
  ));

  const handleMbClick = (i) => {
    setMbIndex(i);
  };

  return (
    <>
      <div className="main_container">
        <Fade duration="2000" triggerOnce>
          <Banner
            img={partnerbanner}
            // caption="Ruby, Bandra(W)"
            // caption="Fifth Avenue Apartment, 5th Road JVPD"
            title="The impetus on Quality isn't negotiable"
            linkhead="Speak To Us Today"
            linkdesc="A call away from your dream home"
            linkurl={aboutUs}
          />
        </Fade>
        <Fade duration="2000" direction="up" triggerOnce>
          <section className="partner_sec1">
            <div className="gallery_container">
              <div className="accordion_sec">
                {width > 600 ? (
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="categories">
                        <h1 className="partner_heading">PARTNER WITH US</h1>
                        {categoryList}
                      </div>
                    </div>
                    <div className="col-lg-7">
                      {partnerIndex === 0 ? <ChannelForm /> : ""}
                      {partnerIndex === 1 ? <LandOwnerForm /> : ""}
                      {partnerIndex === 2 ? <RedevelopForm /> : ""}
                      {partnerIndex === 3 ? <VendorForm /> : ""}
                    </div>
                  </div>
                ) : (
                  <div className="mb_categories_accordion_wrapper">
                    <h1 className="partner_heading">PARTNER WITH US</h1>
                    {mbCategoryList}
                  </div>
                )}
              </div>
            </div>
          </section>
        </Fade>
      </div>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
};

export default ChannelPartners;
