import { useEffect, useState } from "react";
import { dropdownarrow, orangecta, uploadicon } from "../../../../images";
import { useNavigate } from "react-router-dom";
import config from "../../../../config/config";
import Joi from "joi";
import _ from "lodash";
import http from "../../../../helpers/http";

const VendorForm = () => {
  const [values, setValues] = useState({
    name: "",
    comp_name: "",
    email: "",
    phone: "",
    website: "",
    description: "",

    document: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");

  let base_url = config.api_url + "/forms/vendor";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Contact Number"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (values.document) {
      if (values.document.size > sizeLimit * 1024 * 1024) {
        errors["document"] = `File needs to be under ${sizeLimit} MB`;
      }
      let allowedExt = [
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-office",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/pdf",
        "application/msword",
        "application/vnd.ms-office",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedExt.includes(values.document.type)) {
        errors["document"] = "Please upload valid file type";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    let formData = new FormData();
    for (const [key, data] of Object.entries(
      _.omit(values, ["document", "phone"])
    )) {
      formData.append(key, data || "");
    }

    if (values.phone) {
      formData.append("phone", `${countryCode}${values.phone}`);
    }
    if (values.document) {
      formData.append("document", values.document);
    }

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, formData);

    if (data) {
      setValues({
        name: "",
        comp_name: "",
        email: "",
        phone: "",
        website: "",
        description: "",
        document: "",
      });
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      console.log(e.target.files);
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.files[0],
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const fetchCodes = async () => {
    const data = await http.get(`${config.api_url}/general/countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data.data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  return (
    <div className="contact_form">
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper form_field_customwidth">
              <input
                type="text"
                className="form_input"
                placeholder="Company Name"
                name="comp_name"
                value={values.comp_name}
                onChange={handleChange}
              />
              <p className="error_message">{formError.comp_name}</p>
            </div>

            <div className="form_field_wrapper form_field_customwidth">
              <input
                type="text"
                className="form_input textarea"
                placeholder="Name of contact person*"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <p className="error_message">{formError.name}</p>
            </div>

            {/* Mobile no box here */}
            <div className="mobile_number_box form_field_wrapper">
              <div className="country_code">
                <select
                  className="form_input countrycode_select"
                  name="country_code"
                  value={countryCode}
                  onChange={(e) => setcountryCode(e.target.value)}
                >
                  {codes.map((code, i) => {
                    return (
                      <option value={`+${code}`} key={i}>
                        {`+${code}`}
                      </option>
                    );
                  })}
                </select>
                <img
                  className="dropwdown_icon"
                  width={16}
                  height={8}
                  loading="lazy"
                  alt="dropdown icon"
                  src={dropdownarrow}
                />
                <p className="error_message"></p>
              </div>
              <div className="mobile_number">
                <input
                  type="tel"
                  className="form_input"
                  placeholder="Contact number*"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                />
                <p className="error_message">{formError.phone}</p>
              </div>
            </div>
            {/* Mobile no box end here */}

            <div className="form_field_wrapper form_field_customwidth">
              <input
                type="text"
                className="form_input textarea"
                placeholder="Website"
                name="website"
                value={values.website}
                onChange={handleChange}
              />
              <p className="error_message">{formError.website}</p>
            </div>

            <div className="form_field_wrapper form_field_customwidth">
              <input
                type="text"
                className="form_input textarea"
                placeholder="Email address*"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              <p className="error_message">{formError.email}</p>
            </div>

            <div className="form_field_wrapper form_field_customwidth">
              <input
                type="text"
                className="form_input"
                placeholder="Description of products sold"
                name="description"
                value={values.description}
                onChange={handleChange}
              />
              <p className="error_message">{formError.description}</p>
            </div>

            <div className="form_field_wrapper form_field_customwidth">
              <p className="upload_heading">Document Upload</p>
              <input
                type="file"
                id="uploadFile"
                hidden
                name="document"
                onChange={handleChange}
              />
              <label htmlFor="uploadFile" className="upload_label">
                <img src={uploadicon} alt="" className="upload_icon" />
                <span className="section_desc">Choose File</span>
              </label>
              <div className="format_size_flex">
                <p className="upload_heading">File Format : PDF, DOC, PPT</p>
                <p className="upload_heading">
                  File Size: Less than {sizeLimit} MB
                </p>
              </div>
              <p className="error_message">{formError.document}</p>
              <p className="">
                {" "}
                {values?.document?.name || "No file selected"}
              </p>
            </div>

            <div className="form_field_wrapper form_field_customwidth form_submit">
              <div className="cta_wrapper">
                <button className="project-button">
                  {loading ? "Loading.." : "Submit"}
                  <img
                    src={orangecta}
                    alt="Button arrow design"
                    className="cta_right_arrow"
                  />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorForm;
