import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./common.scss";
import Routing from "./Routing";
import MetaDecorator from "./components/MetaDecorator/MetaDecorator";

function App() {
  return (
    <>
     <MetaDecorator
        metaTitle="Nagpal"
        metaDesc="Nagpal Builders"
        canonicalLink={window.location.href}
      />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Routing />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
