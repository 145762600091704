import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Homepage from "./pages/Homepage/Homepage";
import About from "./pages/About-Us/About";
import Career from "./pages/Careers/Careers";
import ChannelPartners from "./pages/PartnerWithUs/ChannelPartners";
import Contact from "./pages/Contact/Contact";
import {
  aboutUs,
  pageNotFound,
  contactUs,
  career,
  buyersguide,
  legal,
  homeloans,
  documentsandstages,
  nri,
  faq,
  projectsRoute,
  projectinner,
  partner,
  thankyouURL,
  thankreceivedURL,
  privacypolicy,
  privacyandcookiepolicyroute,
} from "./helpers/constant-words";
import { useWindowSize } from "react-use";
import { enquirewhatsapp, mobileenquire } from "./images";
import { NavLink } from "react-router-dom";
import "./common.scss";
import EnquireForm from "./components/EnquireForm/EnquireForm";
import BuyersGuide from "./pages/BuyersGuide/BuyersGuide";
import Legal from "./pages/BuyersGuide/Legal";
import HomeLoans from "./pages/BuyersGuide/HomeLoans";
import DocumentsAndStages from "./pages/BuyersGuide/DocumentsAndStages";
import NRI from "./pages/BuyersGuide/NRI";
import Faq from "./pages/Faq/Faq";
import Privacypolicy from "./pages/Privacypolicy/Privacypolicy";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Projects from "./pages/Projects/Projects";
import ProjectInner from "./pages/ProjectInner/ProjectInner";
import Thankyou from "./pages/ThankYou/Thankyou";
// import Thank from "./pages/Thank/Thank";
import Thank_received from "./pages/Thank/Thank";

function Routing() {
  const { width: windowWidth } = useWindowSize();
  const location = useLocation();
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Homepage />} />

        {/* About US */}
        <Route exact path={aboutUs} element={<About />} />

        {/* Projects */}
        <Route exact path={projectsRoute} element={<Projects />} />

        {/* Project inner */}
        <Route exact path={projectinner} element={<ProjectInner />} />

        {/* Careers */}
        <Route exact path={career} element={<Career />} />

        {/* Contact */}
        <Route exact path={contactUs} element={<Contact />} />

        {/* Partner With US */}
        <Route exact path={partner} element={<ChannelPartners />} />

        {/* Buyers Guide */}
        <Route exact path={buyersguide} element={<BuyersGuide />} />
        <Route exact path={legal} element={<Legal />} />
        <Route exact path={homeloans} element={<HomeLoans />} />
        <Route
          exact
          path={documentsandstages}
          element={<DocumentsAndStages />}
        />
        <Route exact path={nri} element={<NRI />} />

        {/* FAQ */}
        <Route exact path={faq} element={<Faq />} />
        <Route exact path={privacypolicy} element={<Privacypolicy />} />
        {/* <Route exact path={thankyouURL} element={<Thankyou />} /> */}
        <Route exact path={thankyouURL} element={<Thank_received />} />
        {/* <Route exact path={thankreceivedURL} element={<Thank_received />} /> */}
        <Route exact path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to={pageNotFound} />} />
      </Routes>
      {/* {location.pathname.match(privacypolicy) === true ? (
         <EnquireForm />
      ) : null} */}
      {/* <EnquireForm /> */}
      <Footer />

      {/* Mobile enquire now and whatsapp sticky buttons */}
      {windowWidth < 600 ? (
        <section className="enquire-now">
          <img
            className="enquire-background"
            src={mobileenquire}
            alt="backrgound img"
            loading="lazy"
          />
          <div className="enquire-flex">
            <a href="https://wa.me/+919819925114">
              <img
                className="enquire-whatsapp-icon"
                src={enquirewhatsapp}
                alt="whatsapp img"
                loading="lazy"
              />
            </a>
            <NavLink to="/contact-us" className="enquire-text-cta">
              Enquire Now
            </NavLink>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default Routing;
