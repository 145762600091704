import React, { useState } from "react";
import "./homeloans.scss";
import "swiper/css";
import { Accordion } from "react-bootstrap";

//components
import Banner from "../../components/Banner/Banner";
import { aboutUs } from "../../helpers/constant-words";

//images
import { faqopen, orangecta, projectsbanner } from "../../images";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
// import { useExternalScript } from "../../hooks/useExternalScript";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

function HomeLoans() {
  const [loaded, setLoaded] = useState(false);
  // const externalScript =
  //   "https://emicalculator.net/widget/2.0/js/emicalc-loader.min.js";
  // const state = useExternalScript(externalScript);

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={projectsbanner}
          caption="Jaswant Heights, Khar (W)"
          title="We believe and practice the saying - nothing but the best"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" direction="up" triggerOnce>
        <div className="my_container">
          <div className="faqs_content">
            <div className="title">
              <h1>HOME LOANS</h1>
            </div>
            <div className="faqs">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What are the age criteria for loan sanctions?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    You need to be of a minimum of 21 years of age for your home
                    loan to get sanctioned and the loan must be closed before
                    you turn 70 years old or when you turn 70.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>What are the basic requirements for Home Loan?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Home Loan applicants need to furnish personal documents,
                    such as Pan Card and Aadhar Card, among others:
                    income-related documents, such as bank account statements:
                    employment/business-related documents, such as salary slips
                    or profit and loss statements: and property-related
                    documents
                    <br />
                    <br />
                    Below are the documents required to apply for a home loan in
                    India
                    <br />
                    <br />
                    <ul className="faq_list">
                      <li>
                        Proof of Identity: Passport/ Voter ID/ Driving License/
                        PAN
                      </li>
                      <li>
                        Proof of Address: Telephone Bill/ Electricity Bill/
                        Passport/ Bank Statement/ Passbook
                      </li>
                      <li>
                        Property Documents: A copy of the original sale deed,
                        NOC from society, allotment-possession letter, etc
                      </li>
                      <li>
                        Proof of Income Income Tax Return (ITR), salary slips,
                        certificate of practice (for professionals), audited
                        financial sheet (for self-employed applicants and
                        professionals), qualification certificate (for
                        professionals), P&L statement (for self-employed
                        applicants), etc
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Documents required for Home Loan</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    KYC Documents
                    <br />
                    <br />
                    <ul className="faq_list">
                      <li>Passport-size photograph.</li>
                      <li>
                        Proof of Identity (PAN Card/ Passport Aadhar Card/
                        Driving License/ Voters ID Card)
                      </li>
                      <li>
                        Proof of Residence (Ration Card/ Electricity Bill/
                        Telephone Bill/ Passbook or Bank Statement with address/
                        Employment Letter)
                      </li>
                      <li>
                        Age proof (Passport/ PAN Card/ Birth Certificate/ Bank
                        passbook/ Driving License 10th Marksheet)
                      </li>
                    </ul>
                    <br />
                    <br />
                    Proof of Income
                    <br />
                    <br />
                    <ul className="faq_list">
                      <li>
                        Latest Salary Slips for the preceding three months
                      </li>
                      <li>Bank account statement for the past six months</li>
                      <li>
                        Form 16 (Part A & Part B) and filed Income Tax Returns
                        (ITR) for the past two years
                      </li>
                      <li>Employee Identity Card </li>
                      <li>
                        Appointment Letter/ Employer Certificate/ Increment
                        Letter (as relevant)
                      </li>
                    </ul>
                    <br />
                    <br />
                    Property Documents
                    <br />
                    <br />
                    <ul className="faq_list">
                      <li>Commencement Certificate</li>
                      <li>Registered agreement for sale</li>
                      <li>Approved building plan copy</li>
                      <li>Payment receipts to the builder</li>
                      <li>Registration receipt</li>
                      <li>Sanction Letter</li>
                      <li>Chain contract from the previous owner</li>
                      <li>Occupancy Certificate</li>
                      <li>Society maintenance bill copy</li>
                      <li>Society Share Certificate</li>
                      <li>Society Registration Certificate</li>
                      <li>Possession Letter / Allotment Letter</li>
                      <li>Copy of electricity bill</li>
                      <li>NOC (NO Objection Certificate) from society</li>
                      <li>Property insurance copy</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      What is a CIBIL Score and what factors affect my CIBIL
                      Score?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    CIBIL Score is a 3-digit numeric summary of your credit
                    history, derived by using details found in the ‘Accounts’
                    and ‘Enquiries’ sections on your CIBIL Report and ranges
                    from 300 to 900. The closer your score is to 900, the higher
                    the chances of your loan application getting approved.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>Why Check Credit Score Before Applying For A Loan?</p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Not everyone loves surprises, especially when it comes to
                    finances. There have been many incidents where potential
                    home buyers have selected the house, made an advance payment
                    and then{" "}
                    <a href="https://www.paisabazaar.com/home-loan/">
                      applied for a home loan
                    </a>{" "}
                    from a reputed bank, only to find that their loan
                    application has been rejected because of low credit scores.
                    At that particular point in time, it gets extremely
                    difficult to arrange for such huge amounts. Other than this,
                    there can be a financial emergency where you need a certain
                    amount for which you have to{" "}
                    <a href="https://www.paisabazaar.com/personal-loan/">
                      take a personal loan
                    </a>{" "}
                    but your CIBIL score does not allow you to get a loan at
                    low-interest rates. If you don’t want to be part of such
                    incidents, it is better to{" "}
                    <a href="https://www.paisabazaar.com/cibil-credit-report/">
                      check your CIBIL score
                    </a>{" "}
                    before applying for a loan.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="cta_wrapper emi-cal">
            <button className="project-button cal-btn">
              <a
                href="https://emicalculator.net/"
                rel="noreferrer"
                target="_blank"
                className="cal-link"
              >
                Click here to see EMI Calculator
                <img
                  src={orangecta}
                  alt="Button arrow design"
                  className="right-arrow cta_right_arrow"
                />
              </a>
            </button>
          </div>

          {/* <div
            id="ecww-widgetwrapper"
            style={{
              minWidth: 250,
              width: "100%",
              marginTop: "3em",
            }}
          >
            <div
              id="ecww-widget"
              style={{
                position: "relative",
                paddingTop: 0,
                height: 0,
                overflow: "hidden",
                paddingBottom: 280,
              }}
            ></div>
            <div
              id="ecww-more"
              style={{
                background: "#333",
                padding: "10 0",
                color: "#FFF",
                textAlign: "center",
                width: "100%",
                clear: "both",
                margin: 0,
                float: "left",
              }}
            >
              <a
                style={{
                  background: "#333",
                  color: "#FFF",
                  textDecoration: "none",
                  borderBottom: "1px dotted #ccc",
                }}
                href="https://emicalculator.net/"
                title="Loan EMI Calculator"
                rel="nofollow"
                target="_blank"
              >
                <button
                  onClick={refreshPage}
                  style={{
                    background: "#333",
                    color: "#FFF",
                    textDecoration: "none",
                    borderBottom: "1px dotted #ccc",
                  }}
                >
                  Click to load EMI Calculator!
                </button>{" "}
                or visit emicalculator.net
              </a>
            </div>
          </div> */}
        </div>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
}

export default HomeLoans;
