import React from "react";
import "./documentsandstages.scss";
import "swiper/css";
import { Accordion } from "react-bootstrap";

//components
import Banner from "../../components/Banner/Banner";
import { aboutUs } from "../../helpers/constant-words";

//images
import { buyersguiddocument, faqopen, partnerbanner } from "../../images";
import { Fade } from "react-awesome-reveal";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import EnquireForm from "../../components/EnquireForm/EnquireForm";

function DocumentsAndStages() {
  return (
    <>
      <Fade duration="2000" triggerOnce>
        <Banner
          img={buyersguiddocument}
          // caption="Fifth Avenue Apartment, 5th Road JVPD"
          title="The impetus on Quality isn't negotiable"
          linkhead="Speak To Us Today"
          linkdesc="A call away from your dream home"
          linkurl={aboutUs}
        />
      </Fade>
      <Fade duration="2000" direction="up" triggerOnce>
        <div className="my_container">
          <div className="faqs_content">
            <div className="title">
              <h1>THE DOCUMENTS AND STAGES</h1>
            </div>
            <div className="faqs">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      Which documents are needed to verify while buying a
                      residential or commercial unit?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ol className="doc_faq_list">
                      <li>
                        The landowner/promoter requires the ownership documents
                        including a title certificate while a developer/promoter
                        who is not an owner but has acquired the development
                        rights requires a development agreement and power of
                        attorney.
                      </li>
                      <li>
                        Certificates like Intimation of Disapproval
                        (IOD)/Commencement Certificate/Development Permission
                        and the building plan/s that are approved by the
                        competent authority.
                      </li>
                      <li>
                        Depending on the nature of the plot or the type of
                        development, other permissions/NOC’s issued by the
                        competent authority.
                      </li>
                      <li>
                        The occupation/Building completion certificate is issued
                        by the competent authority if the construction is
                        completed.
                      </li>
                      <li>
                        The sale agreement draft, brochure for specifications,
                        layouts and amenities in the layout.
                      </li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      Is the agreement for sale executed by the
                      developer/promoter?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    As per the provisions of the Real Estate (Regulation &
                    Development) Act, 206, the developer/promoter needs to
                    execute an agreement for sale if the intended purchaser
                    wishes to proceed with the purchase of premises.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <div className="div_line"></div>
                  <Accordion.Header>
                    <p>
                      What is the procedure for the execution of the agreement
                      for sale?
                    </p>
                    <img
                      className="float-right icon"
                      src={faqopen}
                      alt="arrow icon"
                    ></img>
                  </Accordion.Header>
                  <Accordion.Body>
                    Step 1 - The stamp duty should be borne and paid by the
                    purchaser as per Section 30 of the Maharashtra Stamp Act,
                    2013 unless there is an agreement. The stamp duty payment is
                    made through E-Payment through GRAS (Govt. Receipt
                    Accounting System). <br />
                    <br />
                    Step 2 - Execution of the agreement for sale by the
                    developer/promoter and the purchaser/allottee(s).
                    <br />
                    <br />
                    Step 3 – Registration of the agreement for sale. For that,
                    an appointment needs to be taken in advance at the local
                    registration office.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </Fade>
      <EnquireForm />
      <ScrollToTop />
    </>
  );
}

export default DocumentsAndStages;
